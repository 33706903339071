@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
@import url(../../node_modules/video-react/dist/video-react.css);
.loginWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative; }

.loginImage {
  background: #29435c;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.8;
  height: 100vh;
  width: 100%;
  position: absolute; }

.linkedInBtn {
  margin: 34px auto 0;
  display: block;
  border: solid;
  float: none;
  border-radius: 24px;
  background: black;
  color: white;
  padding: 6px 20px;
  box-shadow: 0 0 50px -6px #929292;
  border: none; }

.loginBtn button {
  border: none;
  padding: 15px;
  float: right; }

.loginBlock {
  z-index: 1;
  max-width: 300px;
  background: white;
  padding: 19px;
  border-radius: 5px;
  transform-style: preserve-3d;
  perspective-origin: 50% 50%;
  transition: box-shadow 0.15s ease-out, transform 0.25s ease; }
  .loginBlock .centerRemember .ant-form-item-children {
    display: flex;
    justify-content: center; }
  .loginBlock .ant-form-item {
    margin: 0 0 12px; }
  .loginBlock.hover--ending {
    transition: box-shadow 0.5s ease; }

.loginLogo {
  width: 50%;
  margin: 10px auto 20px;
  display: block; }

.mediaListImg {
  object-fit: cover;
  width: 100%;
  height: auto; }

.videoCards {
  margin: 0 8px 0 !important; }
  .videoCards .ant-card-body {
    display: none; }

.uploadDrag .ant-upload.ant-upload-drag {
  background: #fafafae6;
  border-radius: 0; }

.ant-upload {
  max-height: 120px !important; }

.mediaTitle {
  overflow-wrap: break-word; }

.videoReplace {
  width: 100%;
  background: #e2e6e9;
  margin-top: 20px;
  padding: 10px; }

.cmtContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%; }

.cmtSidebar {
  height: 100%;
  max-width: 100%;
  background: #dee2e6 !important; }

::-webkit-scrollbar {
  display: none; }

.cmtContainer ::-webkit-scrollbar {
  display: block !important; }

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px; }

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5); }

.hideAnno {
  z-index: 99;
  margin: 1px;
  width: auto; }

.moderate-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 12;
  background: #000000;
  opacity: 0.6; }

.moderate-detail-wrap
.video-details-wrapper
.video-react
.video-react-big-play-button {
  display: none; }

.moderate-media-wrap .moderate-btn-bg {
  background: #001528bd;
  z-index: 50;
  height: 350px;
  position: absolute;
  pointer-events: none; }

.moderate-media-wrap .moderate-txt {
  position: absolute;
  z-index: 100;
  top: 53%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  color: #f5f5f5;
  font-size: 12px;
  padding-left: 15px;
  padding-right: 15px; }

.moderate-media-wrap .moderate-btn {
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  color: #f5f5f5;
  font-size: 12px;
  padding-left: 15px;
  padding-right: 15px;
  line-height: 34px;
  border: 0;
  margin-top: -30px;
  cursor: pointer; }
  @media (min-width: 640px) {
    .moderate-media-wrap .moderate-btn {
      padding-left: 50px;
      padding-right: 50px;
      line-height: 50px;
      font-size: 16px; } }

.moderate-media-wrap .hvKdFB {
  position: absolute;
  right: inherit;
  bottom: inherit;
  top: 50%;
  transform: translateX(-50%);
  left: 50%;
  width: 100%;
  background: transparent;
  font-size: 12px;
  font-weight: 500;
  text-align: center; }
  @media (min-width: 640px) {
    .moderate-media-wrap .hvKdFB {
      width: 33%;
      font-size: 16px; } }

.moderate-body .video-details-wrapper {
  z-index: inherit; }

.moderate-body .moderate-media-wrap {
  position: relative;
  z-index: 20; }

.moderate-body .sidebar-toggle-btn,
.moderate-body .annotation-toggle-btn {
  z-index: 12 !important; }

.moderate-body .moderate-player-wrap {
  top: 0 !important; }

.moderate-body .hObiiS {
  background: #64a15c; }

.mederate-title {
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%; }

.annotation-toggle-btn {
  background: #001427 !important; }
  .annotation-toggle-btn:hover {
    background: rgba(0, 0, 0, 0.62) !important; }

.reaction-block {
  display: block;
  padding-left: 40px; }
  .reaction-block .dislike-icon {
    margin-left: 28px; }
  .reaction-block .like-icon,
  .reaction-block .dislike-icon {
    font-size: 18px;
    margin-right: 7px;
    vertical-align: middle; }
    .reaction-block .like-icon:hover,
    .reaction-block .dislike-icon:hover {
      opacity: 0.7;
      color: #58a34a; }
  .reaction-block .like-count,
  .reaction-block .dislike-count {
    display: inline-block;
    vertical-align: middle;
    font-size: 12px; }

.thum-update {
  position: fixed;
  bottom: 14px;
  right: 14px;
  width: 300px;
  border-radius: 2px;
  background: #001528;
  box-shadow: 0px 0px 50px 1px #7171711f; }
  .thum-update canvas {
    margin: 0 0 !important;
    width: 100% !important;
    border: none !important;
    border-radius: 2px;
    object-fit: contain;
    max-height: 200px; }
  .thum-update button {
    margin: 7px;
    float: right; }

.media-button-block,
.video-player-contents {
  display: inline-block; }
  .media-button-block .bottom-buttons,
  .video-player-contents .bottom-buttons {
    font-size: 12px;
    line-height: 17px;
    border-radius: 0;
    background: #548f4c;
    display: inline-block !important; }
  .media-button-block .bottom-buttons,
  .video-player-contents .bottom-buttons {
    margin-right: 15px !important; }

.media-detailed-wrapper {
  height: 1300px;
  overflow-y: hidden !important; }
  .media-detailed-wrapper .video-details-wrapper {
    background: #f2f2f2; }
  .media-detailed-wrapper .Comment-block {
    background: #ffffff;
    margin-top: 15px; }
  .media-detailed-wrapper .page-info {
    display: inline-block;
    vertical-align: middle;
    margin-left: 24px;
    font-size: 25px;
    color: #ffffff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    @media (max-width: 576px) {
      .media-detailed-wrapper .page-info {
        font-size: 20px;
        margin-left: 10px;
        width: calc(100% - 128px); } }
  .media-detailed-wrapper .back-btn {
    border: 0;
    padding: 6px 14px;
    font-size: 16px;
    color: #000000;
    background: #ffffff;
    cursor: pointer;
    border-radius: 0;
    vertical-align: middle; }
    .media-detailed-wrapper .back-btn:hover {
      background: rgba(255, 255, 255, 0.9); }
    @media (max-width: 576px) {
      .media-detailed-wrapper .back-btn {
        padding: 3px 8px;
        font-size: 13px; } }

.sharedLogo {
  width: 200px;
  padding: 15px;
  margin: 0 auto;
  display: block; }

.shareIcons {
  width: 60px;
  margin: 20px auto 0;
  display: block;
  cursor: pointer; }

.ant-modal-content {
  border-radius: 1px !important; }
  .ant-modal-content .ant-modal-header {
    background-color: #001528 !important;
    border-radius: 1px !important; }
    .ant-modal-content .ant-modal-header .ant-modal-title {
      color: #fff !important; }
    .ant-modal-content .ant-modal-header .anticon {
      color: #fff !important; }

.ant-calendar-picker-container {
  z-index: 1070 !important; }

h2 {
  font-weight: 350; }

.ant-alert-warning {
  border: 1px solid #83838326 !important;
  background-color: #33333314 !important;
  margin: 12px 0px !important;
  border-radius: 2px !important; }

button.ant-btn.bottom-buttons,
.ant-btn {
  border-radius: 1px !important;
  border: none !important; }

.head-button-wrapper button.ant-btn:active,
.head-button-wrapper button.ant-btn:focus {
  background: none !important; }

.ant-input {
  border-radius: 2px !important; }

.ant-select-selection {
  margin-right: 10px;
  border-radius: 2px !important;
  width: 100%; }

.ant-switch-checked {
  background-color: #66ac58 !important; }

.ant-menu-item-selected {
  border-bottom: solid 0px !important; }

.ant-list-item-meta-avatar {
  margin-right: 0 !important; }

.ant-anchor-wrapper {
  margin-left: 0 !important;
  padding-left: 0 !important; }

.ant-anchor-ink:before {
  display: none !important; }

.bodyPanelWrapper.ant-layout-content {
  border-right: solid 1px #eaebea; }

.ant-list-item {
  margin-bottom: 5px !important; }

.leftColumn {
  background: #f2f4f6; }

.ant-upload-list-text {
  background: #fff; }

.listEvents {
  max-height: 100%;
  overflow: scroll; }

.ant-layout-sider-children {
  background: #f2ecec; }

.ant-layout-sider-zero-width-trigger {
  z-index: 999; }

li {
  list-style: none; }

.wallBtns {
  margin: -51px 0 10px 30px; }
  .wallBtns .moderateWall {
    text-align: center;
    display: inline-block;
    border: none;
    padding: 15px 35px;
    width: 180px;
    background: linear-gradient(90deg, #575757 31%, #1c1c1c 69%);
    position: relative;
    color: white;
    border-radius: 20px 20px 0 0; }
  .wallBtns .moderateWallActive {
    text-align: center;
    display: inline-block;
    background: white;
    color: black;
    position: relative;
    z-index: 99; }
  .wallBtns .assetsBtn {
    text-align: center;
    display: inline-block;
    border: none;
    position: relative;
    padding: 12px 22px;
    width: 110px;
    background: linear-gradient(90deg, #575757 31%, #1c1c1c 69%);
    margin: 6px 3% -20px 0;
    border-radius: 20px 20px 0 0;
    float: right;
    color: white; }
  .wallBtns .assetsBtnActive {
    text-align: center;
    display: inline-block;
    border: none;
    position: relative;
    padding: 12px 22px;
    width: 110px;
    background: white;
    margin: 6px 3% -20px 0;
    border-radius: 20px 20px 0 0;
    float: right;
    color: black; }
  .wallBtns .shareWall {
    text-align: center;
    display: inline-block;
    border: none;
    position: relative;
    padding: 15px 35px;
    width: 180px;
    background: linear-gradient(90deg, #575757 31%, #1c1c1c 69%);
    color: white;
    margin-left: -10px;
    border-radius: 20px 20px 0 0; }
  .wallBtns .shareWallActive {
    text-align: center;
    display: inline-block;
    background: white;
    color: black;
    z-index: 99; }

.sideBarWrapper {
  padding: 30px !important; }
  .sideBarWrapper ul {
    list-style: none; }

input[type="submit"] {
  background: black;
  color: white;
  border: none;
  border-radius: 22px;
  padding: 7px 29px; }

button.primaryBtn {
  background: none;
  border: none; }

.ant-btn-primary {
  text-transform: none !important; }

.loginBlock button.ant-btn.ant-btn-primary {
  background: #000;
  color: #fff !important;
  border-radius: 20px;
  padding: 7px 38px !important;
  border: none;
  overflow: visible;
  height: auto; }

.primaryButton {
  background-color: white !important;
  padding: 5px 10px;
  margin-right: 10px !important;
  border-radius: 20px !important;
  color: black !important;
  border: solid 1px #000 !important; }

.menuBtn {
  background: none;
  color: white;
  border-radius: 20px;
  width: 100%;
  display: block; }

.black-btn-1 {
  width: 100%;
  border-radius: 0px !important;
  padding: 10px;
  color: green !important;
  border: solid 2px green;
  background: #fff !important;
  margin-bottom: 5px; }

.annotation-submit {
  margin: 5px;
  border-radius: 0px;
  background: #58a349;
  color: white;
  border: none;
  float: right; }

/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace; }

*,
::after,
::before {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

@-ms-viewport {
  width: device-width; }

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[data-original-title],
abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: 0.5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }

a:hover {
  color: #0056b3;
  text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):focus {
  outline: 0; }

code,
kbd,
pre,
samp {
  font-family: monospace,monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[type=reset],
[type=submit],
button,
html [type=button] {
  -webkit-appearance: button; }

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type=checkbox],
input[type=radio] {
  box-sizing: border-box;
  padding: 0; }

input[type=date],
input[type=datetime-local],
input[type=month],
input[type=time] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto; }

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

.h1,
h1 {
  font-size: 2.5rem; }

.h2,
h2 {
  font-size: 2rem; }

.h3,
h3 {
  font-size: 1.75rem; }

.h4,
h4 {
  font-size: 1.5rem; }

.h5,
h5 {
  font-size: 1.25rem; }

.h6,
h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

.small,
small {
  font-size: 80%;
  font-weight: 400; }

.mark,
mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }

.blockquote-footer::before {
  content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code,
kbd,
pre,
samp {
  font-family: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }

a > code {
  color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }

.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0; }

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%; }

.col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%; }

.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%; }

.col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%; }

.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%; }

.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%; }

.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%; }

.col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%; }

.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1; }

.order-last {
  -webkit-box-ordinal-group: 14;
  -ms-flex-order: 13;
  order: 13; }

.order-0 {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0; }

.order-1 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1; }

.order-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2; }

.order-3 {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3; }

.order-4 {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4; }

.order-5 {
  -webkit-box-ordinal-group: 6;
  -ms-flex-order: 5;
  order: 5; }

.order-6 {
  -webkit-box-ordinal-group: 7;
  -ms-flex-order: 6;
  order: 6; }

.order-7 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 7;
  order: 7; }

.order-8 {
  -webkit-box-ordinal-group: 9;
  -ms-flex-order: 8;
  order: 8; }

.order-9 {
  -webkit-box-ordinal-group: 10;
  -ms-flex-order: 9;
  order: 9; }

.order-10 {
  -webkit-box-ordinal-group: 11;
  -ms-flex-order: 10;
  order: 10; }

.order-11 {
  -webkit-box-ordinal-group: 12;
  -ms-flex-order: 11;
  order: 11; }

.order-12 {
  -webkit-box-ordinal-group: 13;
  -ms-flex-order: 12;
  order: 12; }

.offset-1 {
  margin-left: 8.333333%; }

.offset-2 {
  margin-left: 16.666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.333333%; }

.offset-5 {
  margin-left: 41.666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.333333%; }

.offset-8 {
  margin-left: 66.666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.333333%; }

.offset-11 {
  margin-left: 91.666667%; }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1; }
  .order-sm-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13; }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0; }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2; }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3; }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4; }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5; }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6; }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7; }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8; }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9; }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10; }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11; }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.333333%; }
  .offset-sm-2 {
    margin-left: 16.666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.333333%; }
  .offset-sm-5 {
    margin-left: 41.666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.333333%; }
  .offset-sm-8 {
    margin-left: 66.666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.333333%; }
  .offset-sm-11 {
    margin-left: 91.666667%; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1; }
  .order-md-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13; }
  .order-md-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0; }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2; }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3; }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4; }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5; }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6; }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7; }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8; }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9; }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10; }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11; }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.333333%; }
  .offset-md-2 {
    margin-left: 16.666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.333333%; }
  .offset-md-5 {
    margin-left: 41.666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.333333%; }
  .offset-md-8 {
    margin-left: 66.666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.333333%; }
  .offset-md-11 {
    margin-left: 91.666667%; } }

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1; }
  .order-lg-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13; }
  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0; }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2; }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3; }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4; }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5; }
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6; }
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7; }
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8; }
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9; }
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10; }
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11; }
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.333333%; }
  .offset-lg-2 {
    margin-left: 16.666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.333333%; }
  .offset-lg-5 {
    margin-left: 41.666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.333333%; }
  .offset-lg-8 {
    margin-left: 66.666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.333333%; }
  .offset-lg-11 {
    margin-left: 91.666667%; } }

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1; }
  .order-xl-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13; }
  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0; }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2; }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3; }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4; }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5; }
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6; }
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7; }
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8; }
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9; }
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10; }
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11; }
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.333333%; }
  .offset-xl-2 {
    margin-left: 16.666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.333333%; }
  .offset-xl-5 {
    margin-left: 41.666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.333333%; }
  .offset-xl-8 {
    margin-left: 66.666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.333333%; }
  .offset-xl-11 {
    margin-left: 91.666667%; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6; }

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6; }

.table tbody + tbody {
  border-top: 2px solid #dee2e6; }

.table .table {
  background-color: #fff; }

.table-sm td,
.table-sm th {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }

.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6; }

.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > td,
.table-primary > th {
  background-color: #b8daff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff; }

.table-secondary,
.table-secondary > td,
.table-secondary > th {
  background-color: #d6d8db; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf; }

.table-success,
.table-success > td,
.table-success > th {
  background-color: #c3e6cb; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb; }

.table-info,
.table-info > td,
.table-info > th {
  background-color: #bee5eb; }

.table-hover .table-info:hover {
  background-color: #abdde5; }

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5; }

.table-warning,
.table-warning > td,
.table-warning > th {
  background-color: #ffeeba; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1; }

.table-danger,
.table-danger > td,
.table-danger > th {
  background-color: #f5c6cb; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7; }

.table-light,
.table-light > td,
.table-light > th {
  background-color: #fdfdfe; }

.table-hover .table-light:hover {
  background-color: #ececf6; }

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6; }

.table-dark,
.table-dark > td,
.table-dark > th {
  background-color: #c6c8ca; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe; }

.table-active,
.table-active > td,
.table-active > th {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #212529; }

.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #32383e; }

.table-dark.table-bordered {
  border: 0; }

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05); }

.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive-sm > .table-bordered {
    border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive-md > .table-bordered {
    border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive-lg > .table-bordered {
    border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive-xl > .table-bordered {
    border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }

.table-responsive > .table-bordered {
  border: 0; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out; }

.form-control::-ms-expand {
  background-color: transparent;
  border: 0; }

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control::placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm,
.input-group-lg > .form-control-plaintext.form-control,
.input-group-lg > .input-group-append > .form-control-plaintext.btn,
.input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
.input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
.input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
.input-group-sm > .form-control-plaintext.form-control,
.input-group-sm > .input-group-append > .form-control-plaintext.btn,
.input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
.input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
.input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text {
  padding-right: 0;
  padding-left: 0; }

.form-control-sm,
.input-group-sm > .form-control,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .input-group-text {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > select.form-control:not([size]):not([multiple]),
select.form-control-sm:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg,
.input-group-lg > .form-control,
.input-group-lg > .input-group-append > .btn,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-prepend > .input-group-text {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > select.form-control:not([size]):not([multiple]),
select.form-control-lg:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }

.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }

.form-check-input:disabled ~ .form-check-label {
  color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: 0.2rem; }

.custom-select.is-valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.was-validated .form-control:valid {
  border-color: #28a745; }

.custom-select.is-valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip,
.form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip,
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip,
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip {
  display: block; }

.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: #28a745; }

.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip,
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip {
  display: block; }

.custom-control-input.is-valid ~ .custom-control-label,
.was-validated .custom-control-input:valid ~ .custom-control-label {
  color: #28a745; }

.custom-control-input.is-valid ~ .custom-control-label::before,
.was-validated .custom-control-input:valid ~ .custom-control-label::before {
  background-color: #71dd8a; }

.custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip,
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip {
  display: block; }

.custom-control-input.is-valid:checked ~ .custom-control-label::before,
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

.custom-control-input.is-valid:focus ~ .custom-control-label::before,
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.custom-file-input.is-valid ~ .custom-file-label,
.was-validated .custom-file-input:valid ~ .custom-file-label {
  border-color: #28a745; }

.custom-file-input.is-valid ~ .custom-file-label::before,
.was-validated .custom-file-input:valid ~ .custom-file-label::before {
  border-color: inherit; }

.custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip,
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip {
  display: block; }

.custom-file-input.is-valid:focus ~ .custom-file-label,
.was-validated .custom-file-input:valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: 0.2rem; }

.custom-select.is-invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545; }

.custom-select.is-invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip,
.form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip,
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip,
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip {
  display: block; }

.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
  color: #dc3545; }

.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip,
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip {
  display: block; }

.custom-control-input.is-invalid ~ .custom-control-label,
.was-validated .custom-control-input:invalid ~ .custom-control-label {
  color: #dc3545; }

.custom-control-input.is-invalid ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid ~ .custom-control-label::before {
  background-color: #efa2a9; }

.custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip,
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip {
  display: block; }

.custom-control-input.is-invalid:checked ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d; }

.custom-control-input.is-invalid:focus ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.custom-file-input.is-invalid ~ .custom-file-label,
.was-validated .custom-file-input:invalid ~ .custom-file-label {
  border-color: #dc3545; }

.custom-file-input.is-invalid ~ .custom-file-label::before,
.was-validated .custom-file-input:invalid ~ .custom-file-label::before {
  border-color: inherit; }

.custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip,
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip {
  display: block; }

.custom-file-input.is-invalid:focus ~ .custom-file-label,
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.form-inline .form-check {
  width: 100%; }

@media (min-width: 576px) {
  .form-inline label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0; }
  .form-inline .form-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0; }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle; }
  .form-inline .form-control-plaintext {
    display: inline-block; }
  .form-inline .input-group {
    width: auto; }
  .form-inline .form-check {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0; }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0; }
  .form-inline .custom-control {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
  .form-inline .custom-control-label {
    margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out; }

.btn:focus,
.btn:hover {
  text-decoration: none; }

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.btn.disabled,
.btn:disabled {
  opacity: 0.65; }

.btn:not(:disabled):not(.disabled) {
  cursor: pointer; }

.btn:not(:disabled):not(.disabled).active,
.btn:not(:disabled):not(.disabled):active {
  background-image: none; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc; }

.btn-primary.focus,
.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf; }

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62; }

.btn-secondary.focus,
.btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b; }

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34; }

.btn-success.focus,
.btn-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430; }

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b; }

.btn-info.focus,
.btn-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }

.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f; }

.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00; }

.btn-warning.focus,
.btn-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500; }

.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130; }

.btn-danger.focus,
.btn-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d; }

.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5; }

.btn-light.focus,
.btn-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df; }

.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124; }

.btn-dark.focus,
.btn-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }

.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d; }

.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff; }

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent; }

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d; }

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }

.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent; }

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745; }

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }

.btn-outline-success.focus,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent; }

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }

.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8; }

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }

.btn-outline-info.focus,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent; }

.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }

.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107; }

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-outline-warning.focus,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent; }

.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545; }

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-outline-danger.focus,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent; }

.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-outline-light.focus,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent; }

.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }

.btn-outline-dark.focus,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent; }

.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }

.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent; }

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent; }

.btn-link.focus,
.btn-link:focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none; }

.btn-link.disabled,
.btn-link:disabled {
  color: #6c757d; }

.btn-group-lg > .btn,
.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-group-sm > .btn,
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }

.btn-block + .btn-block {
  margin-top: 0.5rem; }

input[type=button].btn-block,
input[type=reset].btn-block,
input[type=submit].btn-block {
  width: 100%; }

.fade {
  opacity: 0;
  transition: opacity 0.15s linear; }

.fade.show {
  opacity: 1; }

.collapse {
  display: none; }

.collapse.show {
  display: block; }

tr.collapse.show {
  display: table-row; }

tbody.collapse.show {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }

.dropdown,
.dropup {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }

.dropdown-item:focus,
.dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa; }

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff; }

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d;
  background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle; }

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto; }

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1; }

.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus,
.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus {
  z-index: 1; }

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px; }

.btn-toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start; }

.btn-toolbar .input-group {
  width: auto; }

.btn-group > .btn:first-child {
  margin-left: 0; }

.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }

.dropdown-toggle-split::after {
  margin-left: 0; }

.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-sm + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-group-lg > .btn + .dropdown-toggle-split,
.btn-lg + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }

.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
  width: 100%; }

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0; }

.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }

.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }

.input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%; }

.input-group > .custom-file,
.input-group > .custom-select,
.input-group > .form-control {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0; }

.input-group > .custom-file:focus,
.input-group > .custom-select:focus,
.input-group > .form-control:focus {
  z-index: 3; }

.input-group > .custom-file + .custom-file,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .form-control,
.input-group > .custom-select + .custom-file,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .form-control,
.input-group > .form-control + .custom-file,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .form-control {
  margin-left: -1px; }

.input-group > .custom-select:not(:last-child),
.input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .custom-select:not(:first-child),
.input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group > .custom-file {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::before {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .custom-file:not(:first-child) .custom-file-label,
.input-group > .custom-file:not(:first-child) .custom-file-label::before {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group-append,
.input-group-prepend {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.input-group-append .btn,
.input-group-prepend .btn {
  position: relative;
  z-index: 2; }

.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .btn,
.input-group-append .input-group-text + .input-group-text,
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-prepend .input-group-text + .input-group-text {
  margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }

.input-group-text input[type=checkbox],
.input-group-text input[type=radio] {
  margin-top: 0; }

.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child),
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child),
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #007bff; }

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff; }

.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d; }

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef; }

.custom-control-label {
  margin-bottom: 0; }

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #dee2e6; }

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(128, 189, 255, 0.5); }

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none; }

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef; }

.custom-select::-ms-expand {
  opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }

.custom-file-input:focus ~ .custom-file-control {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.custom-file-input:focus ~ .custom-file-control::before {
  border-color: #80bdff; }

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse"; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(calc(2.25rem + 2px) - 1px * 2);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0; }

.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }

.nav-link:focus,
.nav-link:hover {
  text-decoration: none; }

.nav-link.disabled {
  color: #6c757d; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }

.nav-tabs .nav-item {
  margin-bottom: -1px; }

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem; }

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6; }

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent; }

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff; }

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 1rem; }

.navbar > .container,
.navbar > .container-fluid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }

.navbar-brand:focus,
.navbar-brand:hover {
  text-decoration: none; }

.navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0; }

.navbar-nav .dropdown-menu {
  position: static;
  float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.navbar-toggler:focus,
.navbar-toggler:hover {
  text-decoration: none; }

.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
  .navbar-expand-sm .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-sm .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto; }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem; }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .navbar-expand-sm .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  .navbar-expand-sm .navbar-toggler {
    display: none; }
  .navbar-expand-sm .dropup .dropdown-menu {
    top: auto;
    bottom: 100%; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
  .navbar-expand-md .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-md .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto; }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem; }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .navbar-expand-md .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  .navbar-expand-md .navbar-toggler {
    display: none; }
  .navbar-expand-md .dropup .dropdown-menu {
    top: auto;
    bottom: 100%; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
  .navbar-expand-lg .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-lg .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto; }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem; }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .navbar-expand-lg .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  .navbar-expand-lg .navbar-toggler {
    display: none; }
  .navbar-expand-lg .dropup .dropdown-menu {
    top: auto;
    bottom: 100%; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
  .navbar-expand-xl .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-xl .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto; }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem; }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .navbar-expand-xl .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  .navbar-expand-xl .navbar-toggler {
    display: none; }
  .navbar-expand-xl .dropup .dropdown-menu {
    top: auto;
    bottom: 100%; } }

.navbar-expand {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start; }

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0; }

.navbar-expand .navbar-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row; }

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute; }

.navbar-expand .navbar-nav .dropdown-menu-right {
  right: 0;
  left: auto; }

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem; }

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap; }

.navbar-expand .navbar-collapse {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto; }

.navbar-expand .navbar-toggler {
  display: none; }

.navbar-expand .dropup .dropdown-menu {
  top: auto;
  bottom: 100%; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7); }

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
  color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }

.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
  color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75); }

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }

.navbar-dark .navbar-text a {
  color: #fff; }

.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
  color: #fff; }

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }

.card > hr {
  margin-right: 0;
  margin-left: 0; }

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem; }

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }

.card-header:first-child {
  border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0; }

.card-header + .list-group .list-group-item:first-child {
  border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }

.card-footer:last-child {
  border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px); }

.card-header-tabs {
  margin-right: -.625rem;
  margin-bottom: -.75rem;
  margin-left: -.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -.625rem;
  margin-left: -.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(.25rem - 1px);
  border-bottom-left-radius: calc(.25rem - 1px); }

.card-deck {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column; }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
  .card-deck .card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0;
    flex: 1 0 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px; } }

.card-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column; }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap; }
  .card-group > .card {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0;
    flex: 1 0 0;
    margin-bottom: 0; }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0; }
  .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .card-group > .card:first-child .card-header,
  .card-group > .card:first-child .card-img-top {
    border-top-right-radius: 0; }
  .card-group > .card:first-child .card-footer,
  .card-group > .card:first-child .card-img-bottom {
    border-bottom-right-radius: 0; }
  .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .card-group > .card:last-child .card-header,
  .card-group > .card:last-child .card-img-top {
    border-top-left-radius: 0; }
  .card-group > .card:last-child .card-footer,
  .card-group > .card:last-child .card-img-bottom {
    border-bottom-left-radius: 0; }
  .card-group > .card:only-child {
    border-radius: 0.25rem; }
  .card-group > .card:only-child .card-header,
  .card-group > .card:only-child .card-img-top {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card-group > .card:only-child .card-footer,
  .card-group > .card:only-child .card-img-bottom {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0; }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top {
    border-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem; }
  .card-columns .card {
    display: inline-block;
    width: 100%; } }

.breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #6c757d;
  content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }

.page-link:hover {
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }

.badge:empty {
  display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }

.badge-primary[href]:focus,
.badge-primary[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #0062cc; }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }

.badge-secondary[href]:focus,
.badge-secondary[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #545b62; }

.badge-success {
  color: #fff;
  background-color: #28a745; }

.badge-success[href]:focus,
.badge-success[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #1e7e34; }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }

.badge-info[href]:focus,
.badge-info[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #117a8b; }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }

.badge-warning[href]:focus,
.badge-warning[href]:hover {
  color: #212529;
  text-decoration: none;
  background-color: #d39e00; }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }

.badge-danger[href]:focus,
.badge-danger[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #bd2130; }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }

.badge-light[href]:focus,
.badge-light[href]:hover {
  color: #212529;
  text-decoration: none;
  background-color: #dae0e5; }

.badge-dark {
  color: #fff;
  background-color: #343a40; }

.badge-dark[href]:focus,
.badge-dark[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }

.alert-primary hr {
  border-top-color: #9fcdff; }

.alert-primary .alert-link {
  color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }

.alert-secondary hr {
  border-top-color: #c8cbcf; }

.alert-secondary .alert-link {
  color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }

.alert-success hr {
  border-top-color: #b1dfbb; }

.alert-success .alert-link {
  color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }

.alert-info hr {
  border-top-color: #abdde5; }

.alert-info .alert-link {
  color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }

.alert-warning hr {
  border-top-color: #ffe8a1; }

.alert-warning .alert-link {
  color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }

.alert-danger hr {
  border-top-color: #f1b0b7; }

.alert-danger .alert-link {
  color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }

.alert-light hr {
  border-top-color: #ececf6; }

.alert-light .alert-link {
  color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }

.alert-dark hr {
  border-top-color: #b9bbbe; }

.alert-dark .alert-link {
  color: #040505; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-color: #007bff;
  transition: width 0.6s ease; }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start; }

.media-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1; }

.list-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }

.list-group-item-action:focus,
.list-group-item-action:hover {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa; }

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem; }

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.list-group-item:focus,
.list-group-item:hover {
  z-index: 1;
  text-decoration: none; }

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  background-color: #fff; }

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }

.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
  color: #004085;
  background-color: #9fcdff; }

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }

.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
  color: #383d41;
  background-color: #c8cbcf; }

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }

.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
  color: #155724;
  background-color: #b1dfbb; }

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }

.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
  color: #0c5460;
  background-color: #abdde5; }

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }

.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
  color: #856404;
  background-color: #ffe8a1; }

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }

.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
  color: #721c24;
  background-color: #f1b0b7; }

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }

.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
  color: #818182;
  background-color: #ececf6; }

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }

.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
  color: #1b1e21;
  background-color: #b9bbbe; }

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5; }

.close:focus,
.close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75; }

.close:not(:disabled):not(.disabled) {
  cursor: pointer; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out,-webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
  transform: translate(0, -25%); }

.modal.show .modal-dialog {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0); }

.modal-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (.5rem * 2)); }

.modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }

.modal-backdrop.fade {
  opacity: 0; }

.modal-backdrop.show {
  opacity: 0.5; }

.modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef; }

.modal-footer > :not(:first-child) {
  margin-left: 0.25rem; }

.modal-footer > :not(:last-child) {
  margin-right: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }

.tooltip.show {
  opacity: 0.9; }

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem; }

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid; }

.bs-tooltip-auto[x-placement^=top],
.bs-tooltip-top {
  padding: 0.4rem 0; }

.bs-tooltip-auto[x-placement^=top] .arrow,
.bs-tooltip-top .arrow {
  bottom: 0; }

.bs-tooltip-auto[x-placement^=top] .arrow::before,
.bs-tooltip-top .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000; }

.bs-tooltip-auto[x-placement^=right],
.bs-tooltip-right {
  padding: 0 0.4rem; }

.bs-tooltip-auto[x-placement^=right] .arrow,
.bs-tooltip-right .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem; }

.bs-tooltip-auto[x-placement^=right] .arrow::before,
.bs-tooltip-right .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000; }

.bs-tooltip-auto[x-placement^=bottom],
.bs-tooltip-bottom {
  padding: 0.4rem 0; }

.bs-tooltip-auto[x-placement^=bottom] .arrow,
.bs-tooltip-bottom .arrow {
  top: 0; }

.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000; }

.bs-tooltip-auto[x-placement^=left],
.bs-tooltip-left {
  padding: 0 0.4rem; }

.bs-tooltip-auto[x-placement^=left] .arrow,
.bs-tooltip-left .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem; }

.bs-tooltip-auto[x-placement^=left] .arrow::before,
.bs-tooltip-left .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem; }

.popover .arrow::after,
.popover .arrow::before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid; }

.bs-popover-auto[x-placement^=top],
.bs-popover-top {
  margin-bottom: 0.5rem; }

.bs-popover-auto[x-placement^=top] .arrow,
.bs-popover-top .arrow {
  bottom: calc((.5rem + 1px) * -1); }

.bs-popover-auto[x-placement^=top] .arrow::after,
.bs-popover-auto[x-placement^=top] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-top .arrow::before {
  border-width: 0.5rem 0.5rem 0; }

.bs-popover-auto[x-placement^=top] .arrow::before,
.bs-popover-top .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25); }

.bs-popover-auto[x-placement^=top] .arrow::after,
.bs-popover-top .arrow::after {
  bottom: 1px;
  border-top-color: #fff; }

.bs-popover-auto[x-placement^=right],
.bs-popover-right {
  margin-left: 0.5rem; }

.bs-popover-auto[x-placement^=right] .arrow,
.bs-popover-right .arrow {
  left: calc((.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0; }

.bs-popover-auto[x-placement^=right] .arrow::after,
.bs-popover-auto[x-placement^=right] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-right .arrow::before {
  border-width: 0.5rem 0.5rem 0.5rem 0; }

.bs-popover-auto[x-placement^=right] .arrow::before,
.bs-popover-right .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25); }

.bs-popover-auto[x-placement^=right] .arrow::after,
.bs-popover-right .arrow::after {
  left: 1px;
  border-right-color: #fff; }

.bs-popover-auto[x-placement^=bottom],
.bs-popover-bottom {
  margin-top: 0.5rem; }

.bs-popover-auto[x-placement^=bottom] .arrow,
.bs-popover-bottom .arrow {
  top: calc((.5rem + 1px) * -1); }

.bs-popover-auto[x-placement^=bottom] .arrow::after,
.bs-popover-auto[x-placement^=bottom] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-bottom .arrow::before {
  border-width: 0 0.5rem 0.5rem 0.5rem; }

.bs-popover-auto[x-placement^=bottom] .arrow::before,
.bs-popover-bottom .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25); }

.bs-popover-auto[x-placement^=bottom] .arrow::after,
.bs-popover-bottom .arrow::after {
  top: 1px;
  border-bottom-color: #fff; }

.bs-popover-auto[x-placement^=bottom] .popover-header::before,
.bs-popover-bottom .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7; }

.bs-popover-auto[x-placement^=left],
.bs-popover-left {
  margin-right: 0.5rem; }

.bs-popover-auto[x-placement^=left] .arrow,
.bs-popover-left .arrow {
  right: calc((.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0; }

.bs-popover-auto[x-placement^=left] .arrow::after,
.bs-popover-auto[x-placement^=left] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-left .arrow::before {
  border-width: 0.5rem 0 0.5rem 0.5rem; }

.bs-popover-auto[x-placement^=left] .arrow::before,
.bs-popover-left .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25); }

.bs-popover-auto[x-placement^=left] .arrow::after,
.bs-popover-left .arrow::after {
  right: 1px;
  border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px); }

.popover-header:empty {
  display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease,-webkit-transform 0.6s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000px;
  perspective: 1000px; }

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block; }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  -webkit-transform: translateX(0);
  transform: translateX(0); }

@supports (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d) {
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.active.carousel-item-right,
.carousel-item-next {
  -webkit-transform: translateX(100%);
  transform: translateX(100%); }

@supports (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d) {
  .active.carousel-item-right,
  .carousel-item-next {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

.active.carousel-item-left,
.carousel-item-prev {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%); }

@supports (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d) {
  .active.carousel-item-left,
  .carousel-item-prev {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5; }

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }

.carousel-indicators li {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  background-color: rgba(255, 255, 255, 0.5); }

.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: ""; }

.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: ""; }

.carousel-indicators .active {
  background-color: #fff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important; }

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }

.embed-responsive::before {
  display: block;
  content: ""; }

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.857143%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important; }

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important; }

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important; }

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important; }

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important; }

.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important; }

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important; }

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important; }

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important; }

.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important; }

.align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important; }

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important; }

.align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important; }

.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important; }

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important; }

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important; }

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important; }

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important; }

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important; }

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important; }

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important; }

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important; }

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important; }

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important; }

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important; }

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-sm-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-sm-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-sm-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-md-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-md-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-md-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-md-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-md-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-lg-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-lg-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-lg-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-xl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-xl-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-xl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: -webkit-sticky) or (position: sticky) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0; }

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  -webkit-clip-path: none;
  clip-path: none; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:focus,
a.text-primary:hover {
  color: #0062cc !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:focus,
a.text-secondary:hover {
  color: #545b62 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:focus,
a.text-success:hover {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:focus,
a.text-info:hover {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:focus,
a.text-warning:hover {
  color: #d39e00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:focus,
a.text-danger:hover {
  color: #bd2130 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:focus,
a.text-light:hover {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:focus,
a.text-dark:hover {
  color: #1d2124 !important; }

.text-muted {
  color: #6c757d !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  ::after,
  ::before {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  blockquote,
  pre {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  img,
  tr {
    page-break-inside: avoid; }
  h2,
  h3,
  p {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
  .table td,
  .table th {
    background-color: #fff !important; }
  .table-bordered td,
  .table-bordered th {
    border: 1px solid #ddd !important; } }

/*# sourceMappingURL=bootstrap.min.css.map */
.app-main-layout .main-header {
  background-color: #f2f2f2;
  height: 65px;
  padding: 0 28px; }
  @media (min-width: 375px) and (max-width: 420px) {
    .app-main-layout .main-header {
      padding: 0 12px; } }
  .app-main-layout .main-header .ant-menu-submenu-title {
    font-size: 15px;
    padding-left: 10px; }
  .app-main-layout .main-header .menu-btn-item.logo-block-wrapper {
    border-bottom: 1px solid #001528 !important;
    margin-right: 25px !important; }

.app-main-layout .main-content-spacer {
  margin-top: 65px; }

.header-notification,
.header-my-profile {
  cursor: pointer;
  vertical-align: middle;
  float: right; }

.ant-menu-submenu-popup {
  z-index: 9 !important; }
  .ant-menu-submenu-popup .header-notification,
  .ant-menu-submenu-popup .header-my-profile {
    float: inherit; }

.main-header {
  position: fixed;
  left: 0;
  top: 0; }
  .main-header .menu-block-wrap.ant-menu-horizontal {
    margin-top: 0;
    height: 65px; }
    .main-header .menu-block-wrap.ant-menu-horizontal .ant-menu-item {
      padding-top: 10px;
      padding-bottom: 10px;
      top: 0; }
  .main-header .logo-block-wrapper {
    background: #293d50;
    width: 300px;
    margin-left: -28px; }
    .main-header .logo-block-wrapper .menu-btn {
      display: none;
      margin-left: 15px;
      width: 22px; }
    @media (max-width: 540px) {
      .main-header .logo-block-wrapper {
        padding-left: 15px !important; } }
  .main-header .ant-menu-horizontal > .logo-block-wrapper:hover {
    background-color: #293d50; }
  .main-header .logo-block {
    width: calc(100% - 37px);
    display: inline-block; }
    .main-header .logo-block figure {
      margin: 0;
      width: 120px;
      margin: auto; }
    .main-header .logo-block .logo-img {
      width: 100%;
      max-width: 100%; }
  .main-header .ant-menu-horizontal {
    border-bottom: none;
    margin-top: 6px;
    background: none; }
  .main-header .ant-menu-horizontal > .ant-menu-item {
    vertical-align: middle; }
  .main-header .ant-menu-item {
    padding: 0;
    margin-right: 10px; }
    .main-header .ant-menu-item a {
      font-weight: 500; }
      @media (min-width: 375px) and (max-width: 420px) {
        .main-header .ant-menu-item a {
          font-size: 13px; } }
  .main-header .menu-btn-item:not(.ant-menu-submenu-horizontal) {
    display: inline-block;
    margin-right: 20px;
    border: none !important; }
    .main-header .menu-btn-item:not(.ant-menu-submenu-horizontal):hover {
      border: none !important; }
    @media (min-width: 375px) and (max-width: 420px) {
      .main-header .menu-btn-item:not(.ant-menu-submenu-horizontal) {
        margin-right: 12px; } }
    .main-header .menu-btn-item:not(.ant-menu-submenu-horizontal) .menu-btn {
      width: 22px;
      color: white;
      line-height: 1;
      -webkit-opacity: 0.8;
      -moz-opacity: 0.8;
      opacity: 0.8;
      -webkit-transition: all 0.2s linear;
      -moz-transition: all 0.2s linear;
      transition: all 0.2s linear; }
      .main-header .menu-btn-item:not(.ant-menu-submenu-horizontal) .menu-btn:hover {
        border: none !important;
        -webkit-opacity: 1;
        -moz-opacity: 1;
        opacity: 1; }
      .main-header .menu-btn-item:not(.ant-menu-submenu-horizontal) .menu-btn .anticon {
        font-size: 22px; }
  .main-header .main-logo {
    width: 120px;
    display: block;
    margin: 0;
    margin-right: 20px;
    border: none !important; }
    .main-header .main-logo:hover {
      border: none !important; }
    .main-header .main-logo img {
      max-width: 100%; }
  .main-header .credit-header-contain {
    display: flex;
    padding: 5px; }
    .main-header .credit-header-contain .credit-title {
      font-weight: 600;
      border: solid #58a34a 2px;
      border-radius: 2px 0px 0px 2px;
      padding: 0 20px;
      line-height: 2.6; }
    .main-header .credit-header-contain .credit-amount {
      color: #fff;
      font-weight: 600;
      border-left: none !important;
      background: #548f4c;
      padding: 0 20px;
      line-height: 2.6;
      border: solid #58a34a 2px;
      border-radius: 0px 2px 2px 0px; }
  .main-header .header-notification {
    text-align: center;
    vertical-align: middle;
    margin-right: 30px;
    border: none !important; }
    .main-header .header-notification:hover {
      border: none !important; }
    .main-header .header-notification .ant-badge {
      display: inline-block;
      vertical-align: middle; }
    .main-header .header-notification .notification-icon {
      font-size: 26px !important;
      color: #646464;
      margin-right: 5px; }
    .main-header .header-notification .ant-badge-count {
      border: none;
      height: 18px;
      padding: 0 5px;
      font-weight: 700;
      right: 2px;
      font-size: 11px;
      background-color: #548f4c;
      line-height: 1.2; }
    .main-header .header-notification .ant-scroll-number-only {
      line-height: 1.2; }
    .main-header .header-notification .ant-scroll-number-only > p {
      line-height: 20px;
      padding-top: 2px;
      line-height: 1.2; }
  .main-header .header-my-profile {
    margin-right: 0;
    border: none !important; }
    .main-header .header-my-profile:hover {
      border: none !important; }
  .main-header .header-user-dp .ant-avatar {
    line-height: 32px; }
    .main-header .header-user-dp .ant-avatar img {
      object-fit: cover; }
  .main-header .header-user-dp .anticon {
    margin: 0 !important;
    font-size: 20px !important; }

.ant-menu-submenu .ant-menu-item .notification-icon {
  font-size: 18px;
  vertical-align: middle; }

.home-body-wrapper .main-header .menu-btn {
  display: inline-block; }

.operations-sidemenu-enabled .main-header .menu-btn-item {
  width: 330px; }

.operations-sidemenu-enabled .main-header .menu-btn {
  display: none; }

@media (min-width: 640px) {
  .main-header .header-my-profile {
    margin-right: 0 !important; }
  .ant-menu-item {
    margin-right: 20px !important; }
  .ant-badge-count {
    right: 6px !important; } }

@media (min-width: 992px) {
  .ant-menu-item {
    margin-right: 40px; } }

@media (max-width: 767px) {
  .main-header .main-logo {
    width: 110px;
    margin-right: 30px; }
  .main-header .ant-menu-item {
    margin-right: 10px; }
    .main-header .ant-menu-item:n-child(3) {
      margin-right: 0; }
  .header-my-profile {
    margin-right: 0; }
  .ant-menu-submenu .ant-menu-item .notification-icon {
    font-size: 22px;
    padding-top: 8px; } }

@media (max-width: 440px) {
  .ant-badge-count {
    right: 10px !important;
    top: 0 !important; } }

@media (min-width: 395px) and (max-width: 540px) {
  .app-main-layout .main-header {
    padding: 0 10px; }
    .app-main-layout .main-header .menu-btn-item {
      margin-right: 10px; }
    .app-main-layout .main-header .main-logo {
      width: 80px; }
    .app-main-layout .main-header .notification-icon {
      font-size: 18px !important; }
    .app-main-layout .main-header .header-my-profile {
      margin-right: 0 !important; }
    .app-main-layout .main-header .ant-avatar {
      width: 25px;
      height: 25px; }
    .app-main-layout .main-header .notification-icon {
      font-size: 18px;
      margin-right: 3px !important; }
    .app-main-layout .main-header .ant-menu-item {
      margin-right: 15px; }
      .app-main-layout .main-header .ant-menu-item a {
        font-size: 14px; }
    .app-main-layout .main-header .ant-badge-count {
      top: -10px !important;
      right: 4px !important; } }

.home-body-wrapper .main-content-wrapper, .app-main-layout .main-content-wrapper {
  padding-left: 50px;
  /* background-image: url("./images/drawBackground.png"); */
  background-color: #fff;
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: cover; }

.events-sidebar .ant-menu {
  line-height: normal; }

.events-sidebar .toggle-sidebar-inner-btn {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  position: fixed;
  left: 80%;
  top: 75px;
  width: 40px;
  height: 40px;
  background-color: #fff;
  color: #333;
  font-size: 20px;
  z-index: 20;
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
  -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1); }
  .events-sidebar .toggle-sidebar-inner-btn:hover {
    background-color: #eee;
    color: #333; }

.events-sidebar .ant-menu-submenu-arrow:before {
  transform: rotate(0deg) translateY(0px) !important; }

.events-sidebar .ant-menu-submenu-arrow:after {
  transform: rotate(90deg) translateY(0px) !important; }

.events-sidebar .ant-menu-submenu-open .ant-menu-item {
  background: #001427;
  color: #fff; }
  .events-sidebar .ant-menu-submenu-open .ant-menu-item:hover {
    color: rgba(254, 254, 254, 0.6); }

.events-sidebar .ant-menu-submenu-open .ant-menu-submenu-arrow {
  margin-top: 5px; }
  .events-sidebar .ant-menu-submenu-open .ant-menu-submenu-arrow:before {
    transform: rotate(45deg) translateY(-4px) !important; }
  .events-sidebar .ant-menu-submenu-open .ant-menu-submenu-arrow:after {
    transform: rotate(135deg) translateY(4px) !important; }

.events-sidebar .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
.events-sidebar .ant-menu.ant-menu-dark .ant-menu-item-selected,
.events-sidebar .ant-menu.ant-menu-dark .ant-menu-item-active {
  background-color: #58a34a;
  color: #fff; }
  .events-sidebar .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected a,
  .events-sidebar .ant-menu.ant-menu-dark .ant-menu-item-selected a,
  .events-sidebar .ant-menu.ant-menu-dark .ant-menu-item-active a {
    color: #fff; }

.events-sidebar .ant-menu-submenu .ant-menu-inline {
  padding: 0; }

.events-sidebar .video-status,
.events-sidebar .dept-status,
.events-sidebar .events-status {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 15px; }

.events-sidebar .video-status {
  background: #64a15c; }

.events-sidebar .dept-status {
  background: #a15c96; }

.events-sidebar .events-status {
  background: #eda929; }

.events-sidebar .event-submenu .ant-menu-submenu-title {
  background-color: #192a3a !important; }

.events-sidebar .nest-submenu .ant-menu-sub a {
  padding-left: 30px; }

.events-sidebar .nest-submenu .ant-menu-submenu-arrow:before {
  transform: rotate(0deg) translateY(0px) !important; }

.events-sidebar .nest-submenu .ant-menu-submenu-arrow:after {
  transform: rotate(90deg) translateY(0px) !important; }

.events-sidebar .nest-submenu.ant-menu-submenu-open .ant-menu-submenu-arrow {
  margin-top: 5px; }
  .events-sidebar .nest-submenu.ant-menu-submenu-open .ant-menu-submenu-arrow:before {
    transform: rotate(45deg) translateY(-4px) !important; }
  .events-sidebar .nest-submenu.ant-menu-submenu-open .ant-menu-submenu-arrow:after {
    transform: rotate(135deg) translateY(4px) !important; }

.events-sidebar .menu-item-solid-background {
  background-color: #29435c;
  font-size: 15px !important;
  height: 48px !important;
  line-height: 48px !important;
  margin: 0 0 4px 0 !important;
  padding-left: 24px !important; }

.events-sidebar .menu-item-title img.sidbarIcon {
  width: 13px;
  margin: 0 11px 0px 0px; }

.events-sidebar .menu-item-title-block {
  margin-bottom: 4px; }

.events-sidebar .ant-menu-submenu-open .ant-menu-item {
  margin: 0 !important;
  padding-left: 24px !important; }
  .events-sidebar .ant-menu-submenu-open .ant-menu-item a {
    color: #fff;
    padding-left: 0 !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

.events-sidebar .side-menu-list-wrap .nest-submenu .ant-menu-submenu-title {
  background: #001427 !important; }

.events-sidebar .side-menu-list-wrap .nest-submenu.ant-menu-submenu-open .ant-menu-item {
  background: #000000 !important; }
  .events-sidebar .side-menu-list-wrap .nest-submenu.ant-menu-submenu-open .ant-menu-item:hover {
    background: #64a15c !important; }

.events-sidebar .sidbar-icon-block {
  padding: 30px 15px 15px;
  height: calc(100vh - 65px);
  position: relative;
  z-index: 15;
  background: #001528; }
  .events-sidebar .sidbar-icon-block .icon-list-block {
    width: 20px;
    display: block;
    margin-bottom: 20px;
    cursor: pointer; }
  .events-sidebar .sidbar-icon-block .add-block {
    height: 30px;
    margin-bottom: 10px;
    position: relative; }
    .events-sidebar .sidbar-icon-block .add-block .plus::before, .events-sidebar .sidbar-icon-block .add-block .plus::after {
      position: absolute;
      content: '';
      background: #fff; }
    .events-sidebar .sidbar-icon-block .add-block .plus::before {
      width: 18px;
      height: 2px;
      top: 8px;
      left: 2px; }
    .events-sidebar .sidbar-icon-block .add-block .plus::after {
      height: 18px;
      width: 2px;
      top: 0;
      left: 10px; }

.events-sidebar .toggle-menu-wrapper {
  height: 100vh;
  max-height: calc(100vh - 65px);
  overflow-y: auto;
  overflow-x: hidden; }
  .events-sidebar .toggle-menu-wrapper .ant-menu-item a {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

.events-sidebar .toggle-menu-wrapper .ant-menu-dark {
  background: #293d50; }

.ant-tooltip-placement-right .ant-tooltip-content .ant-tooltip-inner {
  background: #fff;
  color: #000;
  border-radius: 0; }
  .ant-tooltip-placement-right .ant-tooltip-content .ant-tooltip-inner span {
    color: #000;
    font-size: 13px; }

.ant-tooltip-placement-right .ant-tooltip-content .ant-tooltip-arrow {
  border-right-color: #fff; }

.events-sidebar .ant-layout-sider {
  position: fixed;
  left: 0;
  top: 65px;
  width: 50px !important;
  min-width: 50px !important;
  z-index: 99;
  background-color: #293d50;
  height: 100vh;
  max-height: calc(100vh - 65px); }
  .events-sidebar .ant-layout-sider .ant-layout-sider-children {
    background: transparent; }
  .events-sidebar .ant-layout-sider .sidebar-top-block {
    padding: 20px; }
  .events-sidebar .ant-layout-sider .add-new-wrap {
    padding: 25px 20px 20px;
    border-bottom: 1px solid #253746;
    margin: -20px -20px 0; }
  .events-sidebar .ant-layout-sider .add-new-edit-btn {
    color: #6a839a;
    font-size: 16px;
    font-weight: 500; }
    .events-sidebar .ant-layout-sider .add-new-edit-btn:hover {
      color: #93b6d6; }
  .events-sidebar .ant-layout-sider .sidebar-main-title {
    padding: 20px 0; }
    .events-sidebar .ant-layout-sider .sidebar-main-title h3 {
      display: inline-block;
      font-size: 15px;
      color: #fff;
      margin-bottom: 0;
      font-weight: 500; }
    .events-sidebar .ant-layout-sider .sidebar-main-title .create-event-btn {
      float: right;
      padding-bottom: 12px;
      font-weight: 700;
      font-size: 16px;
      color: #58a34a; }
      .events-sidebar .ant-layout-sider .sidebar-main-title .create-event-btn span {
        font-size: 20px; }
      .events-sidebar .ant-layout-sider .sidebar-main-title .create-event-btn:hover {
        -webkit-opacity: 0.8;
        -moz-opacity: 0.8;
        opacity: 0.8; }
  .events-sidebar .ant-layout-sider .sidebar-search .ant-input-wrapper .ant-input {
    height: 36px;
    color: #0c0c0c;
    background-color: #dadee2;
    border-radius: 0 !important;
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }
    .events-sidebar .ant-layout-sider .sidebar-search .ant-input-wrapper .ant-input::placeholder {
      color: rgba(12, 12, 12, 0.3); }
  .events-sidebar .ant-layout-sider .sidebar-search .ant-input-wrapper .ant-input-group-addon {
    background-color: #dadee2;
    border: none;
    border-left: none;
    border-radius: 0; }
    .events-sidebar .ant-layout-sider .sidebar-search .ant-input-wrapper .ant-input-group-addon .ant-btn {
      height: 33px;
      color: rgba(12, 12, 12, 0.3);
      background-color: transparent;
      border: none; }
  .events-sidebar .ant-layout-sider .ant-menu-submenu {
    padding-bottom: 0;
    background: #001427; }
    .events-sidebar .ant-layout-sider .ant-menu-submenu .ant-menu-submenu-title {
      background-color: #29435c;
      font-size: 15px !important;
      height: 48px;
      line-height: 48px;
      margin: 0;
      padding-left: 24px !important; }
      .events-sidebar .ant-layout-sider .ant-menu-submenu .ant-menu-submenu-title a {
        color: rgba(255, 255, 255, 0.8);
        font-weight: 500; }
      .events-sidebar .ant-layout-sider .ant-menu-submenu .ant-menu-submenu-title .nav-text {
        font-size: 15px;
        font-weight: 500; }
      .events-sidebar .ant-layout-sider .ant-menu-submenu .ant-menu-submenu-title .ant-menu-submenu-arrow::before, .events-sidebar .ant-layout-sider .ant-menu-submenu .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
        width: 10px;
        height: 2px; }
      .events-sidebar .ant-layout-sider .ant-menu-submenu .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
        -webkit-transform: rotate(45deg) translateY(-4px);
        transform: rotate(45deg) translateY(-4px); }
      .events-sidebar .ant-layout-sider .ant-menu-submenu .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
        -webkit-transform: rotate(-45deg) translateY(4px);
        transform: rotate(-45deg) translateY(4px); }
      .events-sidebar .ant-layout-sider .ant-menu-submenu .ant-menu-submenu-title .menu-item-block {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
      @media (min-width: 768px) {
        .events-sidebar .ant-layout-sider .ant-menu-submenu .ant-menu-submenu-title {
          font-size: 20px; } }
  .events-sidebar .ant-layout-sider .ant-menu-item {
    border-bottom: none !important; }
    .events-sidebar .ant-layout-sider .ant-menu-item > a {
      color: rgba(255, 255, 255, 0.6); }
      .events-sidebar .ant-layout-sider .ant-menu-item > a:hover {
        color: rgba(255, 255, 255, 0.9); }
  .events-sidebar .ant-layout-sider .ant-layout-sider-zero-width-trigger {
    display: none; }
  .events-sidebar .ant-layout-sider .sidbar-icon-block {
    width: 100%; }

.ant-menu-dark.ant-menu-submenu-popup .ant-menu-vertical-left.ant-menu-sub,
.ant-menu-dark.ant-menu-submenu-popup .ant-menu-vertical-right.ant-menu-sub,
.ant-menu-dark.ant-menu-submenu-popup .ant-menu-vertical.ant-menu-sub {
  background-color: #001528;
  color: #000;
  padding: 15px 0; }
  .ant-menu-dark.ant-menu-submenu-popup .ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
  .ant-menu-dark.ant-menu-submenu-popup .ant-menu-vertical-right.ant-menu-sub .ant-menu-item,
  .ant-menu-dark.ant-menu-submenu-popup .ant-menu-vertical.ant-menu-sub .ant-menu-item {
    border-bottom: none !important;
    margin: 0; }
  .ant-menu-dark.ant-menu-submenu-popup .ant-menu-vertical-left.ant-menu-sub a,
  .ant-menu-dark.ant-menu-submenu-popup .ant-menu-vertical-right.ant-menu-sub a,
  .ant-menu-dark.ant-menu-submenu-popup .ant-menu-vertical.ant-menu-sub a {
    color: rgba(255, 255, 255, 0.6); }
    .ant-menu-dark.ant-menu-submenu-popup .ant-menu-vertical-left.ant-menu-sub a:hover,
    .ant-menu-dark.ant-menu-submenu-popup .ant-menu-vertical-right.ant-menu-sub a:hover,
    .ant-menu-dark.ant-menu-submenu-popup .ant-menu-vertical.ant-menu-sub a:hover {
      color: rgba(255, 255, 255, 0.9); }

.sidebar-top-block .logo-block {
  max-width: 142px;
  display: block;
  margin: 25px auto; }

.sidebar-top-block .logo-img {
  width: 100%; }

.sidebar-top-block figure {
  margin-bottom: 0; }

.sidemenu-wrapper {
  display: block; }

body {
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  transition: all 0.2s linear; }
  body .events-sidebar .toggle-menu-wrapper {
    left: -300px;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    transition: all 0.2s linear; }

.sidemenu-enabled .ant-layout-sider {
  width: 300px !important;
  width: 300px !important; }

.sidemenu-enabled .events-sidebar .ant-layout-sider .sidbar-icon-block {
  width: 16.66%; }

.sidemenu-enabled .events-sidebar .toggle-menu-wrapper {
  left: 0; }

.sidemenu-enabled .toggle-sidebar-inner-btn {
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  visibility: visible; }

@media (max-width: 419px) {
  .sidemenu-enabled .events-sidebar .ant-layout-sider {
    width: 80% !important;
    max-width: 300px !important;
    min-width: 80% !important; } }

@media (min-width: 420px) {
  .sidemenu-enabled .events-sidebar .ant-layout-sider {
    max-width: 300px !important; }
  .events-sidebar .toggle-sidebar-inner-btn {
    left: 300px; } }

@media (min-width: 1140px) {
  .sidemenu-enabled .main-content-wrapper {
    padding-left: 300px;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    transition: all 0.2s linear; }
  .sidemenu-enabled .toggle-sidebar-inner-btn {
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out; } }

.ant-layout-footer.main-footer {
  background-color: #ededed;
  padding: 10px 25px; }

.pagination-wrap,
.table-has-pagination .ant-pagination {
  border-top: 1px solid #e1e1e1;
  padding-top: 20px; }
  .pagination-wrap.ant-pagination,
  .table-has-pagination .ant-pagination.ant-pagination {
    border-top: none;
    padding-top: 20px; }
  .pagination-wrap.odd,
  .table-has-pagination .ant-pagination.odd {
    margin: 10px -20px 0;
    padding-left: 20px; }
  .pagination-wrap .ant-pagination-item,
  .table-has-pagination .ant-pagination .ant-pagination-item {
    border: none;
    line-height: 32px; }
    .pagination-wrap .ant-pagination-item:hover,
    .table-has-pagination .ant-pagination .ant-pagination-item:hover {
      background-color: #e3eae2; }
    .pagination-wrap .ant-pagination-item a,
    .table-has-pagination .ant-pagination .ant-pagination-item a {
      font-weight: 600;
      font-size: 14px;
      color: #839ba6; }
  .pagination-wrap .ant-pagination-item-active,
  .table-has-pagination .ant-pagination .ant-pagination-item-active {
    background-color: #58a34a; }
    .pagination-wrap .ant-pagination-item-active:hover,
    .table-has-pagination .ant-pagination .ant-pagination-item-active:hover {
      background-color: #58a34a; }
    .pagination-wrap .ant-pagination-item-active a,
    .table-has-pagination .ant-pagination .ant-pagination-item-active a {
      color: #fff; }
    .pagination-wrap .ant-pagination-item-active:hover a,
    .table-has-pagination .ant-pagination .ant-pagination-item-active:hover a {
      color: #fff; }
  .pagination-wrap .ant-pagination-next .ant-pagination-item-link,
  .pagination-wrap .ant-pagination-prev .ant-pagination-item-link,
  .table-has-pagination .ant-pagination .ant-pagination-next .ant-pagination-item-link,
  .table-has-pagination .ant-pagination .ant-pagination-prev .ant-pagination-item-link {
    border: none; }
  .pagination-wrap .ant-pagination-next a:hover,
  .pagination-wrap .ant-pagination-prev a:hover,
  .table-has-pagination .ant-pagination .ant-pagination-next a:hover,
  .table-has-pagination .ant-pagination .ant-pagination-prev a:hover {
    background-color: #e3eae2; }
  .pagination-wrap .ant-pagination-next.ant-pagination-disabled a:hover,
  .pagination-wrap .ant-pagination-prev.ant-pagination-disabled a:hover,
  .table-has-pagination .ant-pagination .ant-pagination-next.ant-pagination-disabled a:hover,
  .table-has-pagination .ant-pagination .ant-pagination-prev.ant-pagination-disabled a:hover {
    background-color: transparent; }

.table-has-pagination .ant-table-pagination.ant-pagination {
  float: none;
  border: none;
  margin: 0 0 30px; }

.notification-wrapper {
  position: relative;
  top: 10px;
  background-color: #fff;
  -webkit-box-shadow: 2px 0 10px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 2px 0 10px rgba(0, 0, 0, 0.24);
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.24);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  overflow: hidden; }
  .notification-wrapper .notification-container {
    background: #fff;
    max-height: 300px;
    overflow-y: auto; }
    .notification-wrapper .notification-container .header-title {
      font-size: 16px;
      display: inline-block;
      color: #000;
      font-weight: 600;
      margin-bottom: 0; }
    .notification-wrapper .notification-container .clear-all-btn {
      float: right;
      border: solid 1px #58a34a;
      padding: 1px 10px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      border-radius: 30px;
      color: #58a34a;
      font-size: 11px;
      font-weight: 700;
      margin-top: 2px; }
      .notification-wrapper .notification-container .clear-all-btn:hover {
        background-color: #58a34a;
        color: #fff; }
    .notification-wrapper .notification-container .ant-menu-item:not(:last-child) {
      margin: 0; }
    .notification-wrapper .notification-container .ant-menu-item {
      border-top: none;
      height: auto;
      line-height: normal; }
    .notification-wrapper .notification-container li {
      background-color: #fff;
      padding: 15px 20px;
      border-top: 1px solid #fff;
      border-bottom: 1px solid #e4e4e4;
      position: relative;
      -webkit-transition: all 0.2s linear;
      -moz-transition: all 0.2s linear;
      transition: all 0.2s linear; }
      .notification-wrapper .notification-container li:not(.ant-menu-item):hover {
        border-color: #58a34a;
        background-color: rgba(88, 163, 74, 0.1); }
      .notification-wrapper .notification-container li .ant-list-item {
        padding: 0; }
    .notification-wrapper .notification-container .ant-list-item-meta-description a {
      min-height: 34px; }
    .notification-wrapper .notification-container .notification-icon-block {
      width: 34px;
      height: 34px;
      border: solid 1px #a3a8ae;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      text-align: center;
      line-height: 30px;
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%); }
      .notification-wrapper .notification-container .notification-icon-block svg {
        display: inline-block;
        vertical-align: middle;
        color: #a3a8ae; }
    .notification-wrapper .notification-container .notification-content-wrapper {
      margin-left: 54px;
      vertical-align: middle;
      display: inline-block;
      padding-right: 15px; }
      .notification-wrapper .notification-container .notification-content-wrapper .time-stamp {
        margin-bottom: 0; }
    .notification-wrapper .notification-container .notification-content-block {
      color: #a3a8ae;
      margin-bottom: 0;
      line-height: 1.3; }
      .notification-wrapper .notification-container .notification-content-block b {
        font-weight: 500;
        color: #777; }
    .notification-wrapper .notification-container .read-btn {
      width: 10px;
      height: 10px;
      background-color: #fff;
      border: solid 2px #58a34a;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%; }
    .notification-wrapper .notification-container .ant-list-item-action li {
      padding: 0;
      border: none;
      background-color: transparent; }
      .notification-wrapper .notification-container .ant-list-item-action li:hover {
        background-color: transparent; }

.ant-dropdown.notification-dropdown-pop {
  width: 100%; }
  .ant-dropdown.notification-dropdown-pop .no-notification-item {
    padding-top: 10px; }
    .ant-dropdown.notification-dropdown-pop .no-notification-item .ant-list-item-meta-title {
      font-weight: 600;
      text-align: center; }

@media (max-width: 767px) {
  .ant-dropdown.notification-dropdown-pop {
    top: 50px !important; } }

@media (min-width: 768px) {
  .ant-dropdown.notification-dropdown-pop {
    width: 80%;
    max-width: 450px; }
  .notification-wrapper {
    top: 10px; } }

.profile-main-wrapper {
  padding: 30px 0; }
  .profile-main-wrapper .profile-container {
    max-width: 1000px;
    margin: auto;
    width: 90%;
    min-height: 70vh; }
    @media (min-width: 1400px) {
      .profile-main-wrapper .profile-container .ant-tabs-vertical.ant-tabs-left > .ant-tabs-content {
        margin-left: 300px !important; } }

@media (min-width: 1400px) {
  .subscription-details-block {
    padding-left: 14px; } }

@media (max-width: 991px) {
  .subscription-details-block {
    margin-bottom: 20px; } }

.subscription-details-block .account-details-block {
  margin-bottom: 20px; }

.subscription-details-block .sub-title,
.subscription-details-block .content,
.subscription-details-block .highlight {
  font-size: 14px;
  color: #342e2e;
  font-family: "Open Sans", sans-serif; }

.subscription-details-block .sub-title {
  font-weight: 600;
  margin-bottom: 10px; }

.subscription-details-block .content {
  display: block; }

.subscription-details-block .highlight {
  color: #64a15c;
  display: inline-block; }

.subscription-details-block .transfer-block .ant-input {
  padding: 10px 16px;
  margin-bottom: 10px; }

@media (min-width: 1400px) {
  .licence-holder-block {
    padding-right: 48px; } }

.licence-holder-block .sub-title {
  font-size: 18px;
  color: #342e2e;
  font-weight: 600;
  margin-bottom: 30px;
  font-family: "Open Sans", sans-serif; }

.licence-holder-block .licence-holder-add-block {
  position: relative; }
  .licence-holder-block .licence-holder-add-block .ant-input {
    padding-bottom: 20px; }
  .licence-holder-block .licence-holder-add-block .user-add-btn {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 12px;
    -webkit-border-radius: 0 3px 0 0;
    -moz-border-radius: 0 3px 0 0;
    border-radius: 0 3px 0 0; }

.licence-holder-block .licence-holder-list {
  border: 1px solid #e8e8e8;
  border-top: none;
  -webkit-border-radius: 0 0 3px 3px;
  -moz-border-radius: 0 0 3px 3px;
  border-radius: 0 0 3px 3px; }
  .licence-holder-block .licence-holder-list .ant-list-item,
  .licence-holder-block .licence-holder-list .ant-list-item-meta-title {
    margin-bottom: 0 !important; }
  .licence-holder-block .licence-holder-list .ant-list-item:hover {
    background: #ededed; }
  .licence-holder-block .licence-holder-list .user-list-content,
  .licence-holder-block .licence-holder-list .ant-list-item-meta-avatar,
  .licence-holder-block .licence-holder-list .ant-list-item-meta-content {
    display: inline-block;
    vertical-align: middle; }
  .licence-holder-block .licence-holder-list .user-list-content {
    padding-left: 10px; }
  .licence-holder-block .licence-holder-list .ant-list-item-meta-content {
    width: calc(100% - 34px); }
  .licence-holder-block .licence-holder-list .user-list-info {
    padding-left: 10px; }
  .licence-holder-block .licence-holder-list .user-note-titile-block {
    font-size: 12px;
    margin-bottom: 0;
    color: #000000;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis; }
  .licence-holder-block .licence-holder-list .user-mail {
    font-size: 11px;
    margin-bottom: 0;
    color: #8e8e8e;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis; }
  .licence-holder-block .licence-holder-list .delete-button {
    display: inline-block;
    text-align: right; }
  .licence-holder-block .licence-holder-list .ant-btn-danger {
    border: none;
    box-shadow: none;
    background: transparent;
    font-size: 10px;
    padding: 0 10px;
    color: #cb1919; }

.wallet-head-block .tab-title {
  margin-top: 8px;
  margin-bottom: 22px; }
  @media (max-width: 575px) {
    .wallet-head-block .tab-title {
      margin-right: 20px; } }

.wallet-head-block .tab-title,
.wallet-head-block .credit-block {
  display: inline-block; }

.wallet-head-block .credit-block {
  float: inherit;
  height: 46px;
  border: solid 2px #64a15c;
  background: #f2ecec;
  margin-bottom: 20px; }
  @media (min-width: 575px) {
    .wallet-head-block .credit-block {
      float: right;
      margin-bottom: 0; } }

.wallet-head-block .credit-text {
  padding: 0 15px;
  color: #548f4c;
  font-size: 16px;
  line-height: 42px;
  display: inline-block; }
  @media (min-width: 1400px) {
    .wallet-head-block .credit-text {
      padding: 0 30px; } }

.wallet-head-block .credit-count {
  padding: 0 15px;
  text-align: center;
  color: #fff;
  font-size: 16px;
  line-height: 42px;
  background: #64a15c;
  display: inline-block; }
  @media (min-width: 1400px) {
    .wallet-head-block .credit-count {
      padding: 0 30px; } }

.wallet-content-wrapper .ant-collapse-item:hover .ant-collapse-header {
  color: #64a15c; }

.wallet-content-wrapper .ant-collapse-content-box {
  padding: 5px !important; }

.wallet-content-wrapper .ant-collapse-item {
  border-color: #f0f0f0 !important; }

.wallet-content-wrapper .ant-collapse-header {
  padding-left: 0 !important; }

.wallet-content-wrapper .sub-title {
  font-size: 18px;
  color: #342e2e;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  margin-bottom: 30px; }

.wallet-content-wrapper .payment-section {
  margin-bottom: 30px; }
  @media (min-width: 992px) {
    .wallet-content-wrapper .payment-section {
      margin-bottom: 0; } }

.wallet-content-wrapper .payment-method-block {
  margin-bottom: 50px; }
  .wallet-content-wrapper .payment-method-block .sub-title {
    margin-bottom: 50px; }
  .wallet-content-wrapper .payment-method-block .ant-list-item {
    margin: 0 !important;
    padding: 0;
    border: 0; }
  .wallet-content-wrapper .payment-method-block .user-note-titile-block {
    color: #342e2e;
    font-size: 15px;
    display: inline-block;
    width: calc(100% - 30px);
    padding-right: 30px;
    margin-bottom: 5px; }
    @media (max-width: 519px) {
      .wallet-content-wrapper .payment-method-block .user-note-titile-block {
        display: block; } }
  .wallet-content-wrapper .payment-method-block .highlight {
    color: #64a15c;
    float: right; }
    @media (max-width: 519px) {
      .wallet-content-wrapper .payment-method-block .highlight {
        display: block;
        float: inherit;
        margin-top: 10px; } }
  .wallet-content-wrapper .payment-method-block .ant-list-item-meta-title {
    position: relative; }
  .wallet-content-wrapper .payment-method-block .delete-icon {
    font-size: 12px;
    padding: 0;
    color: #bc5151;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%); }
  .wallet-content-wrapper .payment-method-block .radio-btn-block {
    display: block; }
    .wallet-content-wrapper .payment-method-block .radio-btn-block .ant-radio {
      margin-right: 5px; }
    .wallet-content-wrapper .payment-method-block .radio-btn-block .ant-radio-wrapper {
      margin-bottom: 5px;
      width: 100% !important; }
      .wallet-content-wrapper .payment-method-block .radio-btn-block .ant-radio-wrapper .card-name-block {
        display: inline-block;
        vertical-align: middle; }
        @media (max-width: 420px) {
          .wallet-content-wrapper .payment-method-block .radio-btn-block .ant-radio-wrapper .card-name-block {
            max-width: 150px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; } }
    .wallet-content-wrapper .payment-method-block .radio-btn-block .ant-radio-inner::after {
      width: 10px;
      height: 10px;
      left: 2px;
      top: 2px; }

.wallet-content-wrapper .transfer-credit-block .sub-title {
  margin-bottom: 20px; }

.wallet-content-wrapper .transfer-credit-block .ant-input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #342e2e;
  opacity: 1; }

.wallet-content-wrapper .transfer-credit-block .ant-input::-moz-placeholder {
  /* Firefox 19+ */
  color: #342e2e;
  opacity: 1; }

.wallet-content-wrapper .transfer-credit-block .ant-input:-ms-input-placeholder {
  /* IE 10+ */
  color: #342e2e;
  /*color: $color;
        opacity: $opacity;*/ }

.wallet-content-wrapper .transfer-credit-block .ant-input::-ms-input-placeholder {
  /* Firefox 18- */
  color: #342e2e;
  opacity: 1; }

.wallet-content-wrapper .transfer-credit-block .ant-input:-moz-placeholder {
  /* Firefox 18- */
  color: #342e2e;
  opacity: 1; }

.wallet-content-wrapper .transfer-credit-block .count-field .ant-input {
  -webkit-border-radius: 2px 0 0 2px;
  -moz-border-radius: 2px 0 0 2px;
  border-radius: 2px 0 0 2px; }
  @media (max-width: 575px) {
    .wallet-content-wrapper .transfer-credit-block .count-field .ant-input {
      border-right: 1px solid #d9d9d9;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px; } }

.wallet-content-wrapper .transfer-credit-block .value-field .ant-input {
  -webkit-border-radius: 0 2px 2px 0;
  -moz-border-radius: 0 2px 2px 0;
  border-radius: 0 2px 2px 0; }

.wallet-content-wrapper .transfer-credit-block .edit-credit-block {
  height: 46px;
  border: 1px solid #d9d9d9;
  padding: 0 10px; }

.wallet-content-wrapper .transfer-credit-block .credit-value {
  line-height: 44px; }

@media (min-width: 1200px) {
  .wallet-content-wrapper .transfer-credit-block {
    padding-right: 30px; } }

@media (min-width: 575px) {
  .wallet-content-wrapper .send-btn-block {
    text-align: right; } }

.buy-more-section .select-credit {
  margin-bottom: 15px;
  -webkit-border-radius: 2px 0 0 2px;
  -moz-border-radius: 2px 0 0 2px;
  border-radius: 2px 0 0 2px;
  border: 1px solid #d9d9d9;
  width: 100%; }
  .buy-more-section .select-credit .ant-select-selection {
    border: none;
    height: 44px;
    line-height: 44px;
    color: #342e2e; }

.buy-more-section .value {
  display: block;
  width: 100%;
  height: 46px;
  line-height: 44px;
  padding: 0 15px;
  margin-bottom: 15px;
  color: #342e2e;
  -webkit-border-radius: 0 2px 2px 0;
  -moz-border-radius: 0 2px 2px 0;
  border-radius: 0 2px 2px 0;
  border: 1px solid #d9d9d9; }

.buy-more-section .image-block {
  text-align: right;
  margin-bottom: 10px; }

.buy-more-section .buy-more-form-block .ant-input {
  padding-left: 35px;
  height: 46px;
  -webkit-border-radius: 2px 2px 0 0;
  -moz-border-radius: 2px 2px 0 0;
  border-radius: 2px 2px 0 0; }

.buy-more-section .buy-more-form-block .ant-checkbox-group {
  margin-top: 20px; }

.buy-more-section .bottom-text {
  font-size: 12px;
  color: #000;
  opacity: 0.3;
  display: block;
  text-align: center; }
  .buy-more-section .bottom-text .lock-icon {
    color: #000;
    display: inline-block;
    margin-right: 6px; }

@media (min-width: 1200px) {
  .buy-more-section {
    padding-left: 15px; } }

.buy-more-section .ant-select-selection__rendered {
  line-height: 44px; }

.buy-more-section .StripeElement {
  padding: 6px;
  border-top: none;
  border: 1px solid #d9d9d9;
  -webkit-border-radius: 0 2px 2px 0;
  -moz-border-radius: 0 2px 2px 0;
  border-radius: 0 2px 2px 0; }
  .buy-more-section .StripeElement > div {
    border: none; }

.buy-more-section .form-text {
  color: #342e2e;
  font-size: 14px;
  margin-bottom: 10px; }
  .buy-more-section .form-text .highlight {
    color: #64a15c;
    font-size: 14px;
    display: inline-block; }

.buy-more-section .ant-checkbox-group-item {
  color: #000;
  font-size: 14px;
  margin-bottom: 10px;
  display: block; }

.buy-more-section .ant-checkbox-inner {
  border-color: #000000; }

.buy-more-section .ant-checkbox-group {
  margin-bottom: 20px; }

@media (min-width: 576px) {
  .buy-more-section .purchase-btn-block {
    padding-left: 10px; } }

@media (min-width: 768px) {
  .buy-more-section .purchase-btn-block {
    display: block;
    padding-left: 0; } }

@media (min-width: 992px) {
  .buy-more-section .purchase-btn-block {
    padding-left: 10px; } }

@media (min-width: 576px) {
  .buy-more-section .purchase-btn-block .invoice-btn-space {
    padding-right: 3px; } }

@media (min-width: 768px) {
  .buy-more-section .purchase-btn-block .invoice-btn-space {
    padding-right: 5px; } }

@media (min-width: 576px) {
  .buy-more-section .purchase-btn-block .fill-btn-space {
    padding-left: 3px; } }

@media (min-width: 768px) {
  .buy-more-section .purchase-btn-block .fill-btn-space {
    padding-left: 5px; } }

.buy-more-section .purchase-btn-block .border-btn,
.buy-more-section .purchase-btn-block .fill-btn {
  width: 100%;
  height: 46px;
  padding: 0 10px;
  font-size: 16px;
  line-height: 42px;
  margin-bottom: 15px;
  border-radius: 0 !important; }
  @media (min-width: 574px) and (max-width: 767px) {
    .buy-more-section .purchase-btn-block .border-btn,
    .buy-more-section .purchase-btn-block .fill-btn {
      font-size: 14px; } }

.buy-more-section .purchase-btn-block .border-btn {
  border: solid 2px #64a15c !important;
  background: #f2ecec;
  color: #548f4c; }
  @media (max-width: 575px) {
    .buy-more-section .purchase-btn-block .border-btn {
      margin-bottom: 15px; } }
  .buy-more-section .purchase-btn-block .border-btn:hover {
    background: #548f4c;
    color: #ffffff; }

.buy-more-section .purchase-btn-block .fill-btn {
  color: #fff;
  background: #64a15c; }
  .buy-more-section .purchase-btn-block .fill-btn:hover {
    background: rgba(84, 143, 76, 0.3); }

.buy-more-section .buy-btn {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  background: #58a34a;
  border-color: #58a34a;
  height: 46px;
  width: 100%;
  text-align: center;
  padding: 0;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.buy-more-section .purchase-btn {
  color: #fff;
  background: #58a34a; }

.buy-more-section .invoice-btn {
  color: #58a34a;
  background: #fff;
  margin-bottom: 15px; }
  @media (min-width: 576px) {
    .buy-more-section .invoice-btn {
      margin-bottom: 0; } }

.wallet-table-wrapp {
  background: #fff;
  padding: 10px;
  margin: 50px 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px; }
  @media (min-width: 1200px) {
    .wallet-table-wrapp {
      padding: 30px; } }
  .wallet-table-wrapp .credit-block {
    float: right;
    margin-bottom: 0; }
  .wallet-table-wrapp .tab-title {
    font-size: 20px;
    margin-bottom: 30px; }
    @media (min-width: 1200px) {
      .wallet-table-wrapp .tab-title {
        font-size: 30px; } }
  .wallet-table-wrapp .wallet-head-block .credit-text {
    font-size: 14px; }
    @media (min-width: 575px) {
      .wallet-table-wrapp .wallet-head-block .credit-text {
        font-size: 16px; } }
  .wallet-table-wrapp .activity-table-wrap .ant-table-body {
    margin: 0 !important; }
  .wallet-table-wrapp .activity-table-wrap .ant-table-thead > tr > th {
    font-weight: 600; }
  .wallet-table-wrapp .activity-table-wrap .ant-table-tbody > tr > td {
    padding: 16px 8px; }
  .wallet-table-wrapp .activity-table-wrap .ant-table-pagination {
    float: inherit;
    margin: 30px 0 15px; }
    .wallet-table-wrapp .activity-table-wrap .ant-table-pagination .ant-pagination-next .ant-pagination-item-link,
    .wallet-table-wrapp .activity-table-wrap .ant-table-pagination .ant-pagination-prev .ant-pagination-item-link {
      border: none; }
    .wallet-table-wrapp .activity-table-wrap .ant-table-pagination .ant-pagination-next {
      margin-left: -8px; }
    .wallet-table-wrapp .activity-table-wrap .ant-table-pagination .ant-pagination-item {
      border: none; }
    .wallet-table-wrapp .activity-table-wrap .ant-table-pagination .ant-pagination-item-active {
      background: #58a34a; }
      .wallet-table-wrapp .activity-table-wrap .ant-table-pagination .ant-pagination-item-active a {
        color: #fff; }

.profile-tabs {
  position: relative; }
  .profile-tabs .user-profile-area {
    position: absolute;
    z-index: 9;
    left: 0;
    top: 10px;
    width: 100%;
    padding: 10px 25px 15px 25px;
    border-bottom: 1px solid #eee; }
    .profile-tabs .user-profile-area .dp-block {
      float: left;
      width: 56px;
      height: 56px;
      position: relative; }
      .profile-tabs .user-profile-area .dp-block:hover .avatar-uploader {
        -webkit-opacity: 1;
        -moz-opacity: 1;
        opacity: 1;
        visibility: visible; }
      .profile-tabs .user-profile-area .dp-block .ant-avatar {
        display: block;
        width: 56px;
        height: 56px;
        line-height: 56px;
        font-size: 30px; }
      .profile-tabs .user-profile-area .dp-block .avatar-uploader {
        margin: 0;
        padding: 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        -webkit-opacity: 0;
        -moz-opacity: 0;
        opacity: 0;
        visibility: hidden;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s linear;
        -moz-transition: all 0.2s linear;
        transition: all 0.2s linear; }
      .profile-tabs .user-profile-area .dp-block .avatar-uploader .ant-upload.ant-upload-select-picture-card {
        border: none;
        width: 100%;
        height: 100%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.5);
        margin: 0; }
        .profile-tabs .user-profile-area .dp-block .avatar-uploader .ant-upload.ant-upload-select-picture-card .ant-upload {
          padding: 0;
          color: #fff;
          font-size: 20px; }
    .profile-tabs .user-profile-area .user-right-block {
      margin-left: 75px;
      padding-top: 10px; }
      .profile-tabs .user-profile-area .user-right-block .hello-prefix {
        display: block;
        font-size: 14px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.5);
        margin-bottom: -2px; }
      .profile-tabs .user-profile-area .user-right-block .profile-name {
        display: block;
        font-size: 14px;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.7);
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
  .profile-tabs .ant-tabs-vertical.ant-tabs-left {
    overflow: visible; }
  .profile-tabs .ant-tabs-vertical.ant-tabs-left > .ant-tabs-bar {
    padding: 110px 0 30px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: solid 1px #eee;
    margin: 0 0 15px;
    float: none;
    border-radius: 4px;
    -webkit-border-radius: 4px; }
  .profile-tabs .ant-tabs-vertical.ant-tabs-left > .ant-tabs-bar .ant-tabs-nav-container {
    margin: 0; }
  .profile-tabs .ant-tabs-vertical.ant-tabs-left > .ant-tabs-bar .ant-tabs-tab {
    text-align: left;
    margin: 0;
    font-weight: 600;
    padding: 10px 25px;
    transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear; }
    .profile-tabs .ant-tabs-vertical.ant-tabs-left > .ant-tabs-bar .ant-tabs-tab:hover {
      background-color: #eee;
      color: rgba(0, 0, 0, 0.65); }
    .profile-tabs .ant-tabs-vertical.ant-tabs-left > .ant-tabs-bar .ant-tabs-tab.ant-tabs-tab-active {
      background-color: #58a34a;
      color: #fff;
      font-weight: 700; }
  .profile-tabs .ant-tabs-vertical.ant-tabs-left > .ant-tabs-content {
    padding: 20px 25px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: solid 1px #eee;
    float: none;
    border-radius: 4px;
    -webkit-border-radius: 4px; }
  .profile-tabs .tab-title {
    font-size: 20px;
    margin-bottom: 30px; }
  .profile-tabs .ant-form-item-label {
    line-height: normal; }
    .profile-tabs .ant-form-item-label label {
      font-weight: 500;
      margin-bottom: 5px;
      color: rgba(0, 0, 0, 0.4);
      display: block; }
  .profile-tabs .max-width-600 {
    max-width: 600px; }
  .profile-tabs .max-width-400 {
    max-width: 400px; }
  .profile-tabs .ant-input {
    height: 40px; }
  .profile-tabs .ant-btn-primary {
    height: 40px;
    font-weight: 600;
    padding: 0 20px;
    letter-spacing: 1px; }
  .profile-tabs .notification-settings .label-block {
    font-weight: 500;
    margin-bottom: 5px;
    color: rgba(0, 0, 0, 0.6);
    display: block; }
  .profile-tabs .notification-settings .pb-3 {
    padding-bottom: 15px; }
  @media (min-width: 768px) {
    .profile-tabs .user-profile-area {
      width: 250px; }
    .profile-tabs .ant-tabs-vertical.ant-tabs-left > .ant-tabs-bar {
      float: left;
      width: 250px;
      margin: 0; }
    .profile-tabs .ant-tabs-vertical.ant-tabs-left > .ant-tabs-content {
      margin-left: 270px !important;
      padding: 30px; } }

@media (min-width: 768px) {
  .profile-main-wrapper {
    padding: 60px 0;
    min-height: calc(100vh - 65px); } }

.ant-list-item.note-list-item {
  background: #33333314;
  border: none;
  padding: 10px 20px;
  border-radius: 2px; }

.side-message-block .binumi-bot {
  display: block;
  position: relative;
  color: #000;
  display: block;
  overflow-wrap: break-word;
  margin-bottom: 5px;
  text-align: left; }

.side-message-block .binumi-bot a {
  color: #ececec !important; }

.side-message-block .binumi-bot .message-text {
  color: #ececec;
  border-top: solid #59a24b;
  background-color: #fff;
  color: #000;
  border-radius: 0px; }
  .side-message-block .binumi-bot .message-text .ant-avatar {
    margin-right: 10px; }
  .side-message-block .binumi-bot .message-text .message-body {
    margin-top: 20px; }

.annotation-content-block {
  width: 100%;
  overflow: auto;
  height: calc(100vh - 88px); }
  .annotation-content-block .annotation-message-wrapper {
    position: relative; }
    .annotation-content-block .annotation-message-wrapper .annotation-message {
      padding: 16px;
      height: auto;
      display: inline-block;
      width: 100%;
      margin-bottom: 4px;
      background: white !important; }

.fUUQTB,
.sc-bwzfXH {
  background: none !important;
  border-radius: 0 !important;
  box-shadow: none !important; }

.fEZJfg,
.sc-dnqmqq {
  display: none !important; }

.hObiiS {
  box-shadow: none !important;
  border: solid 1.5px white !important; }

.new-annotation-wrapper .annotation-body-block {
  display: block;
  background: white;
  padding: 8px 13px 48px 13px;
  max-width: 270px;
  min-width: 270px; }

.new-annotation-wrapper .annotation-head-block {
  display: block;
  margin-bottom: 2px;
  background: white;
  padding: 8px 13px;
  max-width: 270px;
  min-width: 270px; }

.new-annotation-wrapper textarea.ant-input {
  border: 0px;
  border-radius: 0px;
  min-width: 60px; }

.new-annotation-wrapper .annotation-submit {
  border-radius: 0px;
  margin: 6px 0px; }

.new-annotation-wrapper .ant-avatar {
  display: inline-block;
  vertical-align: middle; }

.new-annotation-wrapper .props-name,
.new-annotation-wrapper .time {
  font-size: 12px;
  color: #000;
  font-weight: 600;
  display: inline-block;
  vertical-align: middle; }

.new-annotation-wrapper .props-name {
  margin-left: 14px;
  margin-bottom: 0;
  width: calc(100% - 92px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.new-annotation-wrapper .time {
  float: right;
  margin-top: 4px;
  background: #f2ecec;
  padding: 2px 6px; }

.rta__autocomplete.rta__autocomplete--left.rta__autocomplete--bottom {
  height: 60px;
  top: -61px !important;
  left: 0px !important;
  width: 300px;
  position: absolute;
  /* right: 0; */
  background: #cccccc;
  overflow: scroll;
  padding: 10px; }

.side-message-block .compose-message-wrapper #message {
  width: 100%;
  height: 70px;
  display: block;
  resize: vertical;
  padding: 10px !important;
  line-height: 1.3;
  border: solid #e6e6e6 5px !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  box-sizing: border-box; }

.main-content-wrapper.vh-100 {
  min-height: 100vh; }

.main-content-wrapper .your-videos-banner:after {
  background-color: #1a3849 !important; }

.main-content-wrapper .department-banner:after {
  background-color: #4083a0 !important; }

.main-content-wrapper .page-inner-banner {
  min-height: 70px;
  background: #58a34a;
  display: flex;
  align-items: center;
  position: relative;
  padding: 11px 25px; }
  .main-content-wrapper .page-inner-banner::after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(88, 163, 74, 0.96); }
  .main-content-wrapper .page-inner-banner .inner-banner-content {
    position: relative;
    z-index: 2;
    padding-right: 45px;
    width: 100%; }
    @media (min-width: 767px) {
      .main-content-wrapper .page-inner-banner .inner-banner-content {
        display: flex;
        align-items: center; } }
    .main-content-wrapper .page-inner-banner .inner-banner-content .bannerIcon {
      width: 33.73px;
      padding: 4px;
      vertical-align: middle;
      margin-right: 30px; }
  .main-content-wrapper .page-inner-banner .event-title-block {
    color: #fff;
    font-size: 24px;
    margin: 15px 0;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: normal;
    max-width: 50%; }
  .main-content-wrapper .page-inner-banner .event-upload-media {
    margin: 0;
    display: block;
    padding-right: 15px; }
  .main-content-wrapper .page-inner-banner .group-name {
    color: #fff;
    align-self: center;
    font-size: 14px;
    font-weight: 500;
    display: inline-block;
    vertical-align: middle;
    margin-left: 20px; }
    @media (max-width: 767px) {
      .main-content-wrapper .page-inner-banner .group-name {
        margin-left: 0;
        display: block; } }

.main-content-wrapper .groups-inner-banner .inner-banner-content {
  padding-right: 45px; }
  @media (max-width: 767px) {
    .main-content-wrapper .groups-inner-banner .inner-banner-content h2 {
      font-size: 16px; } }

.main-content-wrapper .go-back-btn {
  font-size: 20px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -2px; }

@media (min-width: 768px) {
  .main-content-wrapper .page-inner-banner .event-admin-block {
    margin-right: 15px;
    display: inline-block; }
  .main-content-wrapper .page-inner-banner .event-title-block {
    margin: 0;
    display: inline-block;
    max-width: 100%; }
  .main-content-wrapper .page-inner-banner .event-upload-media {
    margin-left: auto; } }

.main-content-inner-wrapper {
  padding: 20px 25px; }

.staff-block-wrapper {
  min-height: 77px;
  overflow-x: auto;
  margin-bottom: 25px;
  background: white;
  margin: 0px 0px 15px 0px;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05); }
  .staff-block-wrapper .eventOwner {
    padding: 10px; }
  .staff-block-wrapper .eventOwnerWrapper {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 7.7px; }
  .staff-block-wrapper .eventOwnerImg {
    width: 43px;
    border-radius: 100%;
    height: 43px;
    object-fit: cover;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center; }
  .staff-block-wrapper .ant-list.ant-list-split {
    min-width: 2000px; }
  .staff-block-wrapper .ant-list-split .ant-list-item {
    padding: 15px 0px 15px 0px;
    border: none;
    vertical-align: middle;
    margin-right: 10px;
    text-align: left;
    display: inline-block;
    transition: all 0.2s linear;
    cursor: pointer; }
    .staff-block-wrapper .ant-list-split .ant-list-item .ant-list-item-meta {
      display: block;
      padding-top: 5px; }
    .staff-block-wrapper .ant-list-split .ant-list-item .ant-list-item-meta-avatar {
      float: left;
      margin-right: 0;
      background-color: #fff; }
      .staff-block-wrapper .ant-list-split .ant-list-item .ant-list-item-meta-avatar .ant-avatar > img {
        object-fit: cover; }
    .staff-block-wrapper .ant-list-split .ant-list-item .ant-list-item-meta-content {
      margin-left: 42px; }
    .staff-block-wrapper .ant-list-split .ant-list-item .ant-list-item-meta-title {
      color: #000;
      font-size: 14px;
      font-weight: 500;
      white-space: nowrap;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis; }
    .staff-block-wrapper .ant-list-split .ant-list-item .user-email,
    .staff-block-wrapper .ant-list-split .ant-list-item .user-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .staff-block-wrapper .ant-list-split .ant-list-item .user-email {
      font-size: 13px;
      color: #646464;
      font-weight: normal;
      line-height: normal;
      margin-top: -3px; }
    .staff-block-wrapper .ant-list-split .ant-list-item .add-staff-text {
      padding-top: 6px; }
    .staff-block-wrapper .ant-list-split .ant-list-item .add-staff-avatar {
      background-color: #58a34a; }
  .staff-block-wrapper .ant-list-split .add-staff-item {
    width: 150px; }
  .staff-block-wrapper .ant-list-split .user-staff-item .ant-list-item-meta-avatar {
    position: relative;
    overflow: hidden; }
    .staff-block-wrapper .ant-list-split .user-staff-item .ant-list-item-meta-avatar::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 32px;
      height: 32px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      background: url("images/trash-icon.png") no-repeat center center #bc5151;
      background-size: 12px 16px;
      -webkit-opacity: 0;
      -moz-opacity: 0;
      opacity: 0;
      visibility: hidden;
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s linear;
      -moz-transition: all 0.2s linear;
      transition: all 0.2s linear; }
  .staff-block-wrapper .ant-list-split .user-staff-item:hover {
    border-color: #bc5151; }
    .staff-block-wrapper .ant-list-split .user-staff-item:hover .ant-list-item-meta-avatar::after {
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
      visibility: visible; }

.events-wrapper .ant-tabs .ant-tabs-bar {
  background-color: #fff;
  border: solid 1px #ccc;
  padding: 10px;
  margin: 0 0 15px 0;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05); }

.events-wrapper .ant-tabs .ant-tabs-extra-content {
  width: 100%; }
  @media (min-width: 992px) {
    .events-wrapper .ant-tabs .ant-tabs-extra-content {
      width: auto; } }

.events-wrapper .ant-tabs .ant-tabs-content {
  border: none;
  border-top: none; }

.events-wrapper .ant-tabs.ant-tabs-card.events-media-tabs .ant-tabs-card-bar .ant-tabs-nav-container {
  height: auto; }

.events-wrapper .ant-tabs.ant-tabs-card.events-media-tabs .ant-tabs-card-bar .ant-tabs-nav {
  border: solid 1px #58a34a;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  height: 43px; }

.events-wrapper .ant-tabs.ant-tabs-card.events-media-tabs .ant-tabs-card-bar .ant-tabs-tab {
  line-height: 42px;
  font-size: 14px;
  font-weight: 500;
  border: none;
  color: #0c0c0c;
  background-color: transparent;
  margin: 0;
  height: 46px;
  position: relative;
  top: -1px;
  padding: 0 20px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border-right: solid 1px #58a34a; }
  .events-wrapper .ant-tabs.ant-tabs-card.events-media-tabs .ant-tabs-card-bar .ant-tabs-tab:last-child {
    border-right: none; }
  .events-wrapper .ant-tabs.ant-tabs-card.events-media-tabs .ant-tabs-card-bar .ant-tabs-tab:hover {
    color: #58a34a; }

.events-wrapper .ant-tabs.ant-tabs-card.events-media-tabs .ant-tabs-card-bar .ant-tabs-tab-active {
  background-color: rgba(88, 163, 74, 0.3); }
  .events-wrapper .ant-tabs.ant-tabs-card.events-media-tabs .ant-tabs-card-bar .ant-tabs-tab-active:hover {
    color: #0c0c0c; }

@media (max-width: 767px) {
  .events-wrapper .ant-tabs .events-media-tabs .ant-tabs-extra-content {
    float: none !important; } }

.tabs-dropdown {
  background-color: #fff;
  padding: 5px 0;
  border: solid 1px #58a34a;
  margin-top: -6px;
  position: relative;
  z-index: 9; }
  .tabs-dropdown .dropdown-item {
    padding: 7px 10px;
    font-size: 14px;
    color: #0c0c0c;
    cursor: pointer;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    transition: all 0.2s linear; }
    .tabs-dropdown .dropdown-item a {
      color: #0c0c0c; }
    .tabs-dropdown .dropdown-item:hover {
      background-color: #eee; }
  .tabs-dropdown--grey {
    border-color: #ccc; }
    .tabs-dropdown--grey .dropdown-item {
      color: #666; }
      .tabs-dropdown--grey .dropdown-item a {
        color: #666; }

.tabs-right-buttons {
  display: block; }
  .tabs-right-buttons .tabs-button {
    border: solid 1px #58a34a;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    line-height: 42px;
    font-size: 14px;
    font-weight: 500;
    color: #0c0c0c;
    background-color: #fff;
    margin: 0;
    margin-right: 10px;
    margin-bottom: 10px;
    height: 43px;
    position: relative;
    padding: 0; }
    .tabs-right-buttons .tabs-button .button-text {
      padding: 0 20px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      min-width: 100px;
      display: inline-block;
      vertical-align: top; }
    .tabs-right-buttons .tabs-button .more-text {
      height: 42px;
      line-height: 32px;
      font-size: 18px;
      display: inline-block;
      vertical-align: top;
      border-left: 1px solid #58a34a;
      padding: 0 12px; }
  .tabs-right-buttons .ant-btn {
    height: 32px;
    color: #646464;
    font-weight: 500;
    text-transform: uppercase;
    margin-left: 10px;
    font-size: 14px; }
    .tabs-right-buttons .ant-btn .icon-block {
      -webkit-opacity: 0.8;
      -moz-opacity: 0.8;
      opacity: 0.8; }
    .tabs-right-buttons .ant-btn .button-label {
      display: none; }
    .tabs-right-buttons .ant-btn > .anticon + span {
      margin: 0; }
    .tabs-right-buttons .ant-btn:hover .icon-block {
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1; }
  .tabs-right-buttons .ant-btn:not(:hover) {
    background-color: #fff; }
  @media (min-width: 992px) {
    .tabs-right-buttons .tabs-button {
      margin-bottom: 0;
      margin-left: 10px;
      margin-right: 0; }
    .tabs-right-buttons .ant-btn {
      font-size: 12px; }
      .tabs-right-buttons .ant-btn .icon-block {
        margin-right: 5px;
        font-size: 14px;
        position: relative;
        top: 1px; }
      .tabs-right-buttons .ant-btn .button-label {
        display: inline-block; } }

.overlayLine {
  position: fixed;
  right: 0px;
  bottom: 0px;
  width: 250px;
  background: #fff;
  margin: 29px;
  padding: 15px 30px;
  border-radius: 2px;
  box-shadow: 1px 1px 22px 12px #e8e8e847; }
  .overlayLine .ant-progress-bg {
    height: 9px;
    border-radius: 7px !important; }
  .overlayLine .ant-progress-text {
    text-align: center; }

.event-media-card {
  position: relative;
  min-height: 202px;
  max-height: 202px;
  min-width: 100%;
  background-color: #eee; }
  .event-media-card .ant-card-bordered {
    border: none; }
  .event-media-card .ant-card-cover .event-media-img {
    min-height: 200px;
    max-height: 200px;
    object-fit: cover;
    background: #001529;
    width: 100%;
    height: 100%;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0; }
  .event-media-card .ant-card-actions > li > span {
    position: static;
    line-height: normal;
    min-width: 0; }
  .event-media-card .ant-card-extra {
    float: none;
    padding: 0; }
  .event-media-card .ant-card-body {
    display: none; }
  .event-media-card .ant-card-actions {
    background: transparent;
    border: none;
    height: 0; }
    .event-media-card .ant-card-actions > li {
      margin: 0;
      float: none; }
  .event-media-card .ant-card-cover a {
    border: solid 0px; }
  .event-media-card .ant-card-cover .hoverCover {
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%; }
    .event-media-card .ant-card-cover .hoverCover:hover {
      background: #00142791; }
  .event-media-card .event-media-wrap .event-media-option {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    text-align: right;
    background: #00142700;
    padding: 10px;
    color: #fff;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    transition: all 0.2s linear; }
    .event-media-card .event-media-wrap .event-media-option:hover {
      background: #00142791; }
  .event-media-card .event-media-wrap .event-media-option .btn {
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    font-size: 16px;
    margin-left: 15px; }
  .event-media-card .event-media-wrap .event-media-option .download-media-btn {
    background-color: transparent;
    padding: 0;
    border: none;
    font-size: 18px;
    color: white; }
  .event-media-card .media-name-icon {
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    transition: all 0.2s linear; }
  .event-media-card:hover .event-media-option .btn,
  .event-media-card:hover .media-name-icon {
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1; }
  .event-media-card .overlay1 {
    background: #00000091;
    text-align: center;
    height: 175px;
    top: 0px;
    position: absolute;
    width: 100%;
    left: 0px; }
    .event-media-card .overlay1 .ant-progress {
      padding-top: 48px; }
    .event-media-card .overlay1 svg.ant-progress-circle {
      display: none; }
    .event-media-card .overlay1 .ant-progress-text {
      color: #fff; }
  .event-media-card .event-media-name {
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 0;
    padding: 5px 10px;
    width: 100%;
    text-align: left;
    background: #001427;
    font-weight: 700;
    color: #fff;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    transition: all 0.2s linear; }
  .event-media-card:hover .event-media-name {
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.92) 100%);
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.92) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.92) 100%); }
  .event-media-card .edit-media-name {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 15px 10px;
    background-color: rgba(88, 163, 74, 0.95);
    z-index: 2; }
    .event-media-card .edit-media-name .ant-input {
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      background-color: transparent;
      border: none;
      border-bottom: 2px solid #fff;
      padding: 0 30px 0 0;
      font-size: 14px;
      font-weight: 700;
      color: #fff; }
    .event-media-card .edit-media-name .save-btn {
      position: absolute;
      right: 15px;
      top: 15px; }
  .event-media-card .event-media-name-wrap {
    float: left;
    font-weight: 300; }
  .event-media-card .event-media-cb {
    float: right; }
  .event-media-card .ant-checkbox-inner {
    background-color: transparent;
    border-width: 2px;
    width: 18px;
    height: 18px; }

.event-media-grid .ant-col-xs-24 {
  padding-left: 10px;
  padding-right: 10px; }

.event-upload-media .ant-upload-list,
.ant-upload-list {
  position: fixed;
  right: 20px;
  bottom: 20px;
  margin: 0px !important;
  border-radius: 10px; }

.ant-upload-list-item {
  height: auto !important; }

.video-player-contents .ant-upload-list-item-info {
  margin: 20px; }

.event-upload-media .ant-upload-list-item,
.ant-upload-list {
  background-color: #fff !important;
  margin: 0 0 1px;
  border-radius: 2px; }

.event-upload-media {
  display: flex;
  align-items: center;
  margin: auto 3% auto auto; }
  @media screen and (max-width: 540px) {
    .event-upload-media {
      margin: 10px 0px 10px 0px; } }
  .event-upload-media .ant-upload {
    max-height: 200px !important; }
  .event-upload-media .ant-upload.ant-upload-drag {
    padding: 10px 20px;
    border: dashed 3px #d5d5d5; }
  .event-upload-media .ant-upload.ant-upload-drag .ant-upload-btn {
    padding: 0; }
  .event-upload-media .ant-upload-drag-icon {
    color: #58a34a;
    font-size: 60px; }
    .event-upload-media .ant-upload-drag-icon .anticon {
      color: inherit;
      font-size: inherit; }
  .event-upload-media .ant-upload-text {
    font-weight: 600;
    color: #000;
    font-size: 16px; }
  .event-upload-media .ant-upload-list {
    margin: 20px 0; }
  .event-upload-media .ant-upload-list-item {
    padding: 20px;
    height: auto;
    background-color: #eee;
    margin: 0 0 1px; }
    .event-upload-media .ant-upload-list-item:hover .ant-upload-list-item-info {
      background-color: transparent; }
    .event-upload-media .ant-upload-list-item .anticon-close {
      font-size: 16px !important;
      right: 10px;
      top: 10px; }
    .event-upload-media .ant-upload-list-item .ant-upload-list-item-info .anticon-loading,
    .event-upload-media .ant-upload-list-item .ant-upload-list-item-info .anticon-paper-clip {
      position: relative;
      float: left;
      font-size: 20px;
      top: 0; }
    .event-upload-media .ant-upload-list-item .ant-upload-list-item-name {
      float: none;
      padding: 0;
      width: auto;
      display: block;
      margin-left: 30px; }
    .event-upload-media .ant-upload-list-item .ant-upload-list-item-progress {
      position: relative;
      bottom: 0;
      left: 0;
      padding: 0; }

.groups-media-list {
  margin-top: 20px;
  background-color: #fff;
  border: solid 1px #d5d5d5;
  padding: 25px 30px; }

.side-message-block {
  position: relative; }
  .side-message-block .ant-input {
    min-height: 60px !important; }
  .side-message-block.is-open .side-message-wrapper {
    right: 0; }
  .side-message-block.is-open span.typingIndicator {
    background: #59a54a;
    width: 100%;
    display: block;
    padding: 6.5px;
    color: white;
    position: absolute;
    bottom: 0;
    z-index: 99;
    width: 86%; }
  .side-message-block.is-open .sidebar-toggle-btn {
    color: #fff; }
    @media (max-width: 767px) {
      .side-message-block.is-open .sidebar-toggle-btn {
        display: none; } }
  .side-message-block .side-message-wrapper {
    position: fixed;
    top: 0px;
    bottom: 0px;
    right: -350px;
    width: 350px;
    background-color: #fff;
    border-left: solid 1px #d5d5d5;
    z-index: 99;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    transition: all 0.2s linear;
    -webkit-box-shadow: -3px 0 5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: -3px 0 5px rgba(0, 0, 0, 0.1);
    box-shadow: -3px 0 5px rgba(0, 0, 0, 0.1); }
  .side-message-block .head-button-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    width: max-content;
    z-index: 12; }
    .side-message-block .head-button-wrapper .sidebar-toggle-btn {
      background: transparent;
      border: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none; }
  .side-message-block .annotation-toggle-btn {
    background: #001427; }
  .side-message-block .sidebar-toggle-btn,
  .side-message-block .annotation-toggle-btn {
    display: inline-block;
    width: 75px;
    height: 70px;
    padding: 0;
    font-size: 24px;
    text-align: center;
    line-height: 60px;
    color: rgba(255, 255, 255, 0.7);
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0; }
    @media (max-width: 767px) {
      .side-message-block .sidebar-toggle-btn,
      .side-message-block .annotation-toggle-btn {
        border: none !important;
        width: 64px; } }
  .side-message-block .message-header {
    padding: 10px 20px;
    border-bottom: 1px solid #e4e4e4;
    position: relative;
    background: #fff; }
    .side-message-block .message-header h4 {
      margin-bottom: 5px;
      color: #58a34a;
      font-size: 18px;
      font-weight: 600;
      padding-right: 20px; }
    .side-message-block .message-header p {
      margin: 0;
      font-size: 13px;
      color: #646464; }
    .side-message-block .message-header .close-message-btn {
      position: absolute;
      right: 20px;
      top: 12px;
      color: #646464;
      font-size: 16px;
      border: none;
      padding: 0; }
      .side-message-block .message-header .close-message-btn:hover {
        color: #333; }
  .side-message-block .message-content-block {
    padding: 20px 0px 90px;
    overflow-y: auto;
    top: 100px;
    bottom: 0px;
    position: absolute;
    width: 100%;
    height: calc(100% - 190px); }
  .side-message-block .group-by-date {
    -webkit-opacity: 0.6;
    -moz-opacity: 0.6;
    opacity: 0.6;
    padding: 1px 10px;
    color: #58a34a;
    font-size: 12px;
    font-weight: 600;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    display: block;
    margin-bottom: 15px;
    text-align: center; }
  .side-message-block .message-received {
    display: block;
    position: relative;
    color: #000;
    display: block;
    overflow-wrap: break-word;
    margin-bottom: 5px;
    text-align: left; }
    .side-message-block .message-received a {
      color: #646464 !important; }
    .side-message-block .message-received .message-text {
      background-color: #fff;
      color: #000;
      border-radius: 0px; }
      .side-message-block .message-received .message-text .ant-avatar {
        margin-right: 10px; }
      .side-message-block .message-received .message-text .message-body {
        margin-top: 20px; }
  .side-message-block .message-sent {
    display: block;
    position: relative;
    color: #000;
    display: block;
    overflow-wrap: break-word;
    margin-bottom: 5px;
    text-align: left; }
    .side-message-block .message-sent a {
      color: #fff !important; }
    .side-message-block .message-sent .message-text {
      background-color: #fff;
      color: #000;
      border-radius: 0px; }
      .side-message-block .message-sent .message-text .ant-avatar {
        margin-right: 10px; }
      .side-message-block .message-sent .message-text .message-body {
        margin-top: 20px; }
  .side-message-block .message-text {
    padding: 20px 25px;
    position: relative;
    line-height: 1.3;
    font-size: 14px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    margin-bottom: 10px; }
  .side-message-block .message-user {
    font-size: 13px;
    font-weight: 500;
    color: #646464;
    display: inline-block;
    margin-right: 8px; }
  .side-message-block .message-date {
    font-size: 12px;
    color: #a3a8ae;
    display: inline-block; }
  .side-message-block .compose-message-wrapper {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    min-height: 100px;
    border: none;
    border-top: 1px solid #d5d5d5;
    background: #e6e6e6; }
    .side-message-block .compose-message-wrapper .ant-form-explain {
      padding: 0px 0px 0px 12px; }
    .side-message-block .compose-message-wrapper .ant-card-body {
      padding: 0 0 45px; }
    .side-message-block .compose-message-wrapper #message {
      height: 70px;
      display: block;
      resize: vertical;
      padding: 15px 50px 15px 40px;
      line-height: 1.3;
      border: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      -webkit-box-sizing: border-box;
      box-sizing: border-box; }
      .side-message-block .compose-message-wrapper #message:focus {
        border: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none; }
    .side-message-block .compose-message-wrapper .rta__textarea {
      width: 93%;
      margin: 10px 0px 0 10px;
      border: none;
      min-height: 100px; }
    .side-message-block .compose-message-wrapper .chat-btn-block {
      background: #fff;
      position: absolute;
      right: 10px;
      bottom: 20px;
      padding-bottom: 5px;
      text-align: right;
      padding-right: 5px;
      width: calc(100% - 20px); }
    .side-message-block .compose-message-wrapper .send-message-btn {
      width: 57px;
      border: none;
      height: 21px;
      color: #fff;
      font-size: 10px;
      background: #64a15c;
      border-radius: 0px;
      padding: 0;
      text-align: center;
      line-height: 20px;
      -webkit-transition: all 0.2s linear;
      transition: all 0.2s linear;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none; }
      .side-message-block .compose-message-wrapper .send-message-btn img {
        width: 22px;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        top: -2px; }
    .side-message-block .compose-message-wrapper .emoji-picker-container {
      -webkit-opacity: 0;
      -moz-opacity: 0;
      opacity: 0;
      visibility: hidden;
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out; }
      .side-message-block .compose-message-wrapper .emoji-picker-container.open {
        -webkit-opacity: 1;
        -moz-opacity: 1;
        opacity: 1;
        visibility: visible; }
      .side-message-block .compose-message-wrapper .emoji-picker-container .emoji-mart {
        width: 100% !important;
        right: 0 !important;
        bottom: 80px !important;
        -webkit-transition: all 0.2s linear;
        -moz-transition: all 0.2s linear;
        transition: all 0.2s linear; }
      .side-message-block .compose-message-wrapper .emoji-picker-container .emoji-mart-category-label span {
        color: rgba(0, 0, 0, 0.4);
        font-weight: 500;
        font-family: "Open Sans", sans-serif;
        font-size: 14px; }
    .side-message-block .compose-message-wrapper .emoji-btn {
      position: absolute;
      left: 10px;
      bottom: 35px;
      font-size: 20px;
      color: rgba(0, 0, 0, 0.4); }
      .side-message-block .compose-message-wrapper .emoji-btn:hover {
        color: rgba(0, 0, 0, 0.7); }

.events-popup .toggle-btns-group::after {
  content: "";
  clear: both;
  display: table; }

.events-popup .toggle-btns-group .ant-form-item {
  margin-bottom: 15px; }

.events-popup.common-form .toggle-btns-group .ant-form-item-label label {
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.6); }

.events-popup.common-form .toggle-btns-grid .ant-form-item-label label {
  color: rgba(0, 0, 0, 0.4); }

.events-popup .ant-form-item [class^="ant-col-"] > .ant-form-item:only-child,
.events-popup .ant-form-item-control > .ant-form-item:last-child {
  margin-bottom: 20px; }

.ant-menu-submenu-popup {
  z-index: 21 !important; }

.group-card-wrapper {
  margin: 20px 0;
  font-size: 0; }
  .group-card-wrapper .edit-status-card {
    display: flex;
    align-items: center;
    justify-content: center; }
  .group-card-wrapper .image-status {
    align-self: stretch;
    background: #ebebeb;
    text-align: center; }
  .group-card-wrapper .text-status {
    padding: 10px; }
  .group-card-wrapper .group-card-item {
    -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    background-color: #fff;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    border: solid 1px #ccc;
    font-size: 14px;
    display: block;
    margin-bottom: 10px; }
    .group-card-wrapper .group-card-item .group-card-title {
      color: #000;
      text-align: left;
      font-size: 14px; }
    .group-card-wrapper .group-card-item .dot {
      height: 20px;
      width: 20px;
      border: solid 5px #f0f2f5;
      border-radius: 50%;
      display: inline-block; }
    .group-card-wrapper .group-card-item .dot-action {
      background-color: #BC5151; }
    .group-card-wrapper .group-card-item .dot-pending {
      background-color: #F9A63E; }
    .group-card-wrapper .group-card-item .dot-complete {
      background-color: #64A25C; }
  .group-card-wrapper .timeline-block {
    font-size: 0; }
    .group-card-wrapper .timeline-block .timeline-item {
      display: inline-block;
      width: 33.33333%;
      vertical-align: top;
      font-size: 14px;
      position: relative; }
      .group-card-wrapper .timeline-block .timeline-item::before {
        content: '';
        position: absolute;
        left: 0;
        top: 5px;
        width: 50%;
        height: 1px;
        z-index: 1;
        background-color: #ccc; }
      .group-card-wrapper .timeline-block .timeline-item::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 5px;
        width: 50%;
        height: 1px;
        z-index: 1;
        background-color: #ccc; }
      .group-card-wrapper .timeline-block .timeline-item--start {
        text-align: left; }
        .group-card-wrapper .timeline-block .timeline-item--start.timeline-item--pending::after, .group-card-wrapper .timeline-block .timeline-item--start.timeline-item--pending::before {
          background-color: #ffad06; }
        .group-card-wrapper .timeline-block .timeline-item--start.timeline-item--pending + .timeline-item--pending::before {
          background-color: #ffad06; }
        .group-card-wrapper .timeline-block .timeline-item--start.timeline-item--pending + .timeline-item--center::before {
          background-color: #ffad06; }
      .group-card-wrapper .timeline-block .timeline-item--center {
        text-align: center; }
      .group-card-wrapper .timeline-block .timeline-item--end {
        text-align: right; }
      .group-card-wrapper .timeline-block .timeline-item--completed::after, .group-card-wrapper .timeline-block .timeline-item--completed::before {
        background-color: #58a34a; }
      .group-card-wrapper .timeline-block .timeline-item--completed + .timeline-item::before {
        background-color: #58a34a; }
      .group-card-wrapper .timeline-block .timeline-item--completed .timeline-icon {
        background-color: #58a34a; }
        .group-card-wrapper .timeline-block .timeline-item--completed .timeline-icon .anticon {
          display: block; }
      .group-card-wrapper .timeline-block .timeline-item--completed .timeline-text {
        color: #58a34a; }
      .group-card-wrapper .timeline-block .timeline-item--completed + .timeline-item--end::after {
        background-color: #58a34a; }
      .group-card-wrapper .timeline-block .timeline-item--pending::after {
        background-color: #ffad06; }
      .group-card-wrapper .timeline-block .timeline-item--pending .timeline-icon {
        background-color: #ffad06; }
        .group-card-wrapper .timeline-block .timeline-item--pending .timeline-icon .anticon {
          display: block; }
      .group-card-wrapper .timeline-block .timeline-item--pending .timeline-text {
        color: #ffad06; }
      .group-card-wrapper .timeline-block .timeline-item--pending + .timeline-item--end::after, .group-card-wrapper .timeline-block .timeline-item--pending + .timeline-item--end::before {
        background-color: #ffad06; }
    .group-card-wrapper .timeline-block .timeline-icon {
      width: 10px;
      height: 10px;
      padding: 0;
      text-align: center;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      background-color: #ccc;
      display: inline-block;
      position: relative;
      vertical-align: top;
      z-index: 2; }
      .group-card-wrapper .timeline-block .timeline-icon .anticon {
        font-size: 6px;
        position: absolute;
        left: 50%;
        top: 50%;
        display: none;
        -webkit-transform: translateX(-50%) translateY(-50%);
        -moz-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        color: #fff; }
    .group-card-wrapper .timeline-block .timeline-text {
      display: block;
      font-size: 11px;
      color: #aaa;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      z-index: 2;
      margin-top: -7px; }

.event-admin-block .event-admin-dp {
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: inline-block; }
  .event-admin-block .event-admin-dp img {
    object-fit: cover; }

.event-admin-block .right-block {
  padding-left: 15px;
  display: inline-block;
  vertical-align: middle; }
  .event-admin-block .right-block .user-name {
    font-size: 16px;
    color: #fff;
    display: block; }
  .event-admin-block .right-block .user-email {
    font-size: 14px;
    color: #fff;
    display: block;
    margin-top: -2px; }

.details-top-wrapper {
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  border: solid 1px #ccc;
  padding: 30px;
  font-size: 14px;
  display: block;
  margin-bottom: 10px; }
  .details-top-wrapper .details-top-item {
    margin-bottom: 15px; }
  .details-top-wrapper .mb-0 {
    margin-bottom: 0; }
  .details-top-wrapper .title-block {
    color: #555;
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: normal; }
  .details-top-wrapper .para-block {
    font-size: 14px;
    color: #555;
    word-break: break-all; }
  .details-top-wrapper .timeline-block {
    font-size: 0; }
    .details-top-wrapper .timeline-block .timeline-item {
      display: block;
      font-size: 14px;
      position: relative;
      padding-top: 30px;
      padding-left: 40px; }
      .details-top-wrapper .timeline-block .timeline-item::after {
        content: '';
        position: absolute;
        left: 13px;
        bottom: 0;
        width: 2px;
        height: 100%;
        z-index: 1;
        background-color: #ccc; }
      .details-top-wrapper .timeline-block .timeline-item--completed::after {
        background-color: #58a34a; }
      .details-top-wrapper .timeline-block .timeline-item--completed .timeline-icon {
        background-color: #58a34a; }
        .details-top-wrapper .timeline-block .timeline-item--completed .timeline-icon .anticon {
          display: block; }
      .details-top-wrapper .timeline-block .timeline-item--completed .timeline-text {
        color: #58a34a; }
      .details-top-wrapper .timeline-block .timeline-item--pending::after {
        background-color: #ffad06; }
      .details-top-wrapper .timeline-block .timeline-item--pending .timeline-icon {
        background-color: #ffad06; }
        .details-top-wrapper .timeline-block .timeline-item--pending .timeline-icon .anticon {
          display: block; }
      .details-top-wrapper .timeline-block .timeline-item--pending .timeline-text {
        color: #ffad06; }
    .details-top-wrapper .timeline-block .timeline-icon {
      width: 26px;
      height: 26px;
      padding: 0;
      text-align: center;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      background-color: #ccc;
      position: relative;
      vertical-align: top;
      z-index: 2;
      position: absolute;
      left: 0;
      bottom: 0; }
      .details-top-wrapper .timeline-block .timeline-icon .anticon {
        font-size: 12px;
        position: absolute;
        left: 50%;
        top: 50%;
        display: none;
        -webkit-transform: translateX(-50%) translateY(-50%);
        -moz-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        color: #fff; }
    .details-top-wrapper .timeline-block .timeline-text {
      display: block;
      font-size: 14px;
      color: #aaa;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      z-index: 2;
      padding-bottom: 2px; }
    @media (min-width: 768px) {
      .details-top-wrapper .timeline-block {
        margin-bottom: 30px; }
        .details-top-wrapper .timeline-block .timeline-item {
          display: inline-block;
          width: 33.33333%;
          vertical-align: top;
          padding-left: 0;
          padding-top: 0;
          text-align: center; }
          .details-top-wrapper .timeline-block .timeline-item::after {
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px; }
        .details-top-wrapper .timeline-block .timeline-icon {
          left: auto;
          right: 0;
          bottom: -13px; }
        .details-top-wrapper .timeline-block .timeline-text {
          padding-bottom: 10px; } }
  .details-top-wrapper .details-block .detail-item {
    font-size: 14px;
    color: #555; }
    .details-top-wrapper .details-block .detail-item strong {
      font-weight: 500;
      margin-right: 5px; }
  .details-top-wrapper .details-block .editor-status .ant-btn-primary {
    padding: 20px;
    line-height: 1px;
    border-radius: 2px !important; }
  .details-top-wrapper .details-block .tabs-button {
    border: solid 2px #ccc;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    line-height: 42px;
    font-size: 14px;
    font-weight: normal;
    color: #666;
    background-color: #fff;
    margin: 0;
    margin-right: 10px;
    margin-bottom: 10px;
    height: 40px;
    position: relative;
    padding: 0;
    width: 100%; }
    .details-top-wrapper .details-block .tabs-button .button-text {
      padding: 0 20px;
      height: 37px;
      line-height: 37px;
      text-align: center;
      min-width: 100px;
      display: inline-block;
      vertical-align: top; }
    .details-top-wrapper .details-block .tabs-button .more-text {
      height: 37px;
      line-height: 29px;
      font-size: 18px;
      float: right;
      border-left: 2px solid #ccc;
      padding: 0 15px; }

.group-specification-wrapper {
  background-color: #fff;
  border: solid 1px #d5d5d5;
  padding: 25px 30px; }
  .group-specification-wrapper .block-title {
    color: #a3a8ae;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
    display: block; }
    .group-specification-wrapper .block-title.has-underline {
      border-bottom: 1px solid #e8e8e8;
      padding-bottom: 5px;
      margin-bottom: 15px; }
  .group-specification-wrapper .block-description {
    font-size: 14px;
    color: #0c0c0c;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    white-space: pre-line !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .group-specification-wrapper .block-value {
    font-size: 14px;
    color: #0c0c0c;
    display: inline-block;
    width: 80px;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    @media (min-width: 1366px) {
      .group-specification-wrapper .block-value {
        width: 100px; } }
  .group-specification-wrapper .specification-item {
    width: 100%;
    margin-bottom: 25px; }
  .group-specification-wrapper .ant-switch {
    margin-left: 30px;
    vertical-align: middle;
    display: inline-block; }
  .group-specification-wrapper .specification-list-item {
    min-height: 45px;
    padding: 10px 0; }

.common-form.group-editor-status-form .label-item {
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 14px;
  color: #555; }

.common-form.group-editor-status-form .ant-btn-primary {
  padding: 0 20px;
  line-height: 1px;
  font-size: 14px;
  letter-spacing: normal;
  font-weight: normal;
  -webkit-border-radius: 2px !important;
  -moz-border-radius: 2px !important;
  border-radius: 2px !important; }

.common-form.group-editor-status-form .mb-xs-10 {
  margin-bottom: 10px; }

@media (min-width: 768px) {
  .common-form.group-editor-status-form .mb-xs-10 {
    margin-bottom: 0; } }

.video-details-wrapper {
  z-index: 10;
  height: 100% !important;
  background-color: #fff;
  border: solid 1px #d5d5d5;
  padding: 20px 25px; }
  .video-details-wrapper .video-react-video {
    background: black; }
  .video-details-wrapper .video-react-video .video-react-big-play-button,
  .video-details-wrapper .video-react .video-react-big-play-button {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -25px;
    margin-top: -25px;
    width: 50px;
    line-height: 40px;
    font-size: 30px;
    height: 50px;
    border: solid 5px rgba(255, 255, 255, 0.2); }
    @media (min-width: 768px) {
      .video-details-wrapper .video-react-video .video-react-big-play-button,
      .video-details-wrapper .video-react .video-react-big-play-button {
        margin-left: -35px;
        margin-top: -35px;
        width: 70px;
        line-height: 60px;
        font-size: 46px;
        height: 70px; } }
  .video-details-wrapper .video-react-video:hover .video-react-big-play-button,
  .video-details-wrapper .video-react:hover .video-react-big-play-button {
    background-color: #58a34a;
    border: solid 5px rgba(255, 255, 255, 0.5); }
  .video-details-wrapper .primaryButton {
    cursor: pointer; }
  .video-details-wrapper .video-player-contents p {
    margin-bottom: 15px; }
  .video-details-wrapper .video-react-video, .video-details-wrapper .video-react.video-react-fluid, .video-details-wrapper .moderate-player-wrap {
    overflow: hidden; }
  .video-details-wrapper .video-details-wrapper .video-react.video-react-fluid {
    height: 100% !important;
    padding-top: 55% !important; }
  .video-details-wrapper .video-react-fluid.video-react {
    padding-top: 55% !important; }
  .video-details-wrapper .video-player-buttons {
    display: flex;
    align-items: flex-end;
    top: 0px;
    width: 100%;
    font-size: 0;
    position: absolute; }
    .video-details-wrapper .video-player-buttons #canvas {
      border: solid 1px grey;
      width: 50%; }
    .video-details-wrapper .video-player-buttons .bottom-buttons {
      height: 40px;
      padding: 0 10px;
      line-height: 40px;
      margin-bottom: 10px;
      margin-right: 10px;
      font-weight: 500;
      display: block; }
      .video-details-wrapper .video-player-buttons .bottom-buttons .anticon {
        font-size: 18px;
        display: inline-block;
        vertical-align: middle; }

.video-details-wrapper .video-react-video video,
.video-details-wrapper .video-react-video .video-react-video, .video-details-wrapper .video-react.video-react-fluid video,
.video-details-wrapper .video-react.video-react-fluid .video-react-video, .video-details-wrapper .moderate-player-wrap video,
.video-details-wrapper .moderate-player-wrap .video-react-video {
  width: 100%; }

.faq-wrapper {
  margin: 40px auto;
  width: 900px;
  max-width: 90%; }
  .faq-wrapper .ant-collapse {
    border-color: #d5d5d5; }
    .faq-wrapper .ant-collapse > .ant-collapse-item {
      -webkit-border-radius: 0 !important;
      -moz-border-radius: 0 !important;
      border-radius: 0 !important; }
    .faq-wrapper .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      background-color: #f3f3f3;
      -webkit-border-radius: 0 !important;
      -moz-border-radius: 0 !important;
      border-radius: 0 !important;
      border-left: solid 5px #58a34a;
      padding: 12px 40px 12px 20px; }
      .faq-wrapper .ant-collapse > .ant-collapse-item > .ant-collapse-header .arrow {
        left: auto;
        right: 20px; }
      .faq-wrapper .ant-collapse > .ant-collapse-item > .ant-collapse-header:not([aria-expanded="true"]) .anticon-right svg {
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        transform: rotate(90deg); }
      .faq-wrapper .ant-collapse > .ant-collapse-item > .ant-collapse-header[aria-expanded="true"] .anticon-right svg {
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        transform: rotate(-90deg); }
    .faq-wrapper .ant-collapse .ant-collapse-content {
      background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0) 49%, rgba(0, 0, 0, 0) 100%);
      background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0) 49%, rgba(0, 0, 0, 0) 100%);
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0) 49%, rgba(0, 0, 0, 0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0d000000', endColorstr='#00000000',GradientType=0 ); }
      .faq-wrapper .ant-collapse .ant-collapse-content .ant-collapse-content-box {
        padding: 16px 20px; }
    .faq-wrapper .ant-collapse p {
      margin-bottom: 0; }
      .faq-wrapper .ant-collapse p + p {
        margin-top: 10px; }

.support-wrapper {
  margin: 40px auto;
  max-width: 90%; }
  .support-wrapper .title-block {
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.7); }
    .support-wrapper .title-block .icon-block {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.6);
      margin-right: 5px; }
    .support-wrapper .title-block.mb-2 {
      margin-bottom: 20px; }
  .support-wrapper .address-block {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    padding: 25px 30px;
    margin-bottom: 30px; }
    .support-wrapper .address-block .address-item {
      margin-bottom: 30px; }
      .support-wrapper .address-block .address-item:last-child {
        margin-bottom: 0; }
  .support-wrapper .contact-form {
    background-color: #fff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: solid 1px #d5d5d5;
    padding: 25px 30px; }
    .support-wrapper .contact-form .ant-input {
      height: 40px; }
    .support-wrapper .contact-form textarea.ant-input {
      height: auto; }
    .support-wrapper .contact-form .ant-form-explain {
      font-size: 12px; }
  .support-wrapper .btn-wrap {
    margin-bottom: 0; }
  .support-wrapper .ant-btn-primary {
    height: 40px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px; }

.event-edit-wrapper .form-wrapper {
  max-width: 700px; }
  .event-edit-wrapper .form-wrapper .ant-input {
    height: 40px; }
  .event-edit-wrapper .form-wrapper textarea.ant-input {
    height: auto; }
  .event-edit-wrapper .form-wrapper .ant-form-explain {
    font-size: 12px; }

.shared-links-wrapper .shared-links-table {
  background-color: #fff;
  border: solid 1px #d5d5d5;
  margin: 30px auto 0;
  width: 900px;
  max-width: 90%;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px; }
  .shared-links-wrapper .shared-links-table .ant-table-tbody > tr > td, .shared-links-wrapper .shared-links-table .ant-table-thead > tr > th {
    padding: 16px 25px; }
  .shared-links-wrapper .shared-links-table .delete-btn {
    font-size: 16px;
    margin-left: 10px;
    color: rgba(0, 0, 0, 0.5); }
    .shared-links-wrapper .shared-links-table .delete-btn:hover {
      color: #ff5656; }

.shared-links-wrapper .ant-table-pagination {
  padding-left: 10px;
  padding-right: 10px; }

.shared-media-wrapper {
  margin-bottom: 20px; }
  .shared-media-wrapper .shared-media-content {
    background-color: #fff;
    border: solid 1px #eaeaea;
    margin: 30px auto 0;
    width: 90%;
    max-width: 90%;
    padding: 15px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px; }
    @media (min-width: 768px) {
      .shared-media-wrapper .shared-media-content {
        padding: 30px; } }
  .shared-media-wrapper .video-details-wrapper {
    padding: 0;
    border: solid 10px #fff;
    background-color: #f3f3f3;
    -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1); }
  .shared-media-wrapper .shared-media-header .main-title {
    font-size: 20px;
    font-weight: 600; }
  .shared-media-wrapper .shared-media-header .message-content {
    background-color: #fafafa;
    padding: 10px 15px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    border: solid 1px #eee;
    font-weight: 500; }
  .shared-media-wrapper .shared-media-header .message-details {
    margin-bottom: 20px; }
  .shared-media-wrapper .media-bottom-block {
    padding: 20px;
    margin-bottom: 20px; }
    @media (min-width: 768px) {
      .shared-media-wrapper .media-bottom-block {
        padding: 20px 40px; } }
    .shared-media-wrapper .media-bottom-block h3 {
      margin-bottom: 5px;
      font-size: 14px;
      font-weight: 500; }
    .shared-media-wrapper .media-bottom-block p {
      margin-bottom: 5px; }
  .shared-media-wrapper .download-btn {
    height: 40px;
    padding: 0 15px;
    line-height: 40px;
    font-weight: 500;
    margin-top: 20px; }
    .shared-media-wrapper .download-btn .anticon {
      font-size: 20px;
      margin-right: 5px;
      display: inline-block;
      vertical-align: middle; }

.registration-request-wrapper .registration-request-table {
  background-color: #fff;
  border: solid 1px #d5d5d5;
  margin: 30px auto 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px; }
  .registration-request-wrapper .registration-request-table .action-btn {
    padding: 0 10px; }

.registration-request-wrapper .ant-table-pagination {
  padding-left: 10px;
  padding-right: 10px; }

.user-listing-wrapper .user-listing-header {
  padding: 10px 20px;
  background-color: #f3f3f3;
  border: solid 1px #d5d5d5;
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0; }

.user-listing-wrapper .search-box {
  height: 36px; }
  .user-listing-wrapper .search-box .ant-btn-primary {
    height: 36px;
    font-size: 16px; }

.user-listing-wrapper .add-user-btn {
  margin-top: 15px;
  background-color: #fff;
  padding: 0 15px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  height: 32px;
  color: #646464;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 32px; }
  .user-listing-wrapper .add-user-btn .anticon {
    font-size: 14px; }
    .user-listing-wrapper .add-user-btn .anticon + span {
      margin-left: 5px; }

.user-listing-wrapper .event-user-list-wrapper {
  background-color: #fff;
  border: solid 1px #d5d5d5;
  border-top: none;
  padding: 25px;
  -webkit-border-radius: 0 0 3px 3px;
  -moz-border-radius: 0 0 3px 3px;
  border-radius: 0 0 3px 3px; }
  .user-listing-wrapper .event-user-list-wrapper .user-list-content {
    padding-right: 40px;
    display: block; }
    .user-listing-wrapper .event-user-list-wrapper .user-list-content .ant-list-item-meta-avatar {
      float: left; }
      .user-listing-wrapper .event-user-list-wrapper .user-list-content .ant-list-item-meta-avatar .ant-avatar {
        width: 44px;
        height: 44px;
        line-height: 44px; }
    .user-listing-wrapper .event-user-list-wrapper .user-list-content .ant-list-item-meta-content {
      display: block;
      margin-left: 60px;
      text-align: left; }
    .user-listing-wrapper .event-user-list-wrapper .user-list-content .ant-list-item-meta-title {
      margin-bottom: 0; }
    .user-listing-wrapper .event-user-list-wrapper .user-list-content .ant-list-item-meta-description {
      word-break: break-all;
      line-height: normal; }
  .user-listing-wrapper .event-user-list-wrapper .ant-list-item-content {
    position: relative; }
  .user-listing-wrapper .event-user-list-wrapper .remove-list-item {
    font-size: 14px;
    padding: 0 10px;
    position: absolute;
    right: 0; }
    .user-listing-wrapper .event-user-list-wrapper .remove-list-item:not(:hover) {
      color: rgba(0, 0, 0, 0.4);
      background-color: #fff; }
    .user-listing-wrapper .event-user-list-wrapper .remove-list-item:focus {
      border-color: #d9d9d9; }

@media (min-width: 768px) {
  .user-listing-wrapper .search-box {
    width: 350px; }
  .user-listing-wrapper .add-user-btn {
    float: right;
    margin-top: 3px; } }

.filter-option {
  font-size: 11px; }

@media (max-width: 560px) {
  .ant-calendar-range {
    width: 315px !important;
    overflow: auto !important; } }

.pdf-content-wrapper {
  display: none; }

.operations-view-wrapper .main-content-wrapper {
  padding-left: 0; }

.operations-view-wrapper .active {
  background: #29435c; }
  .operations-view-wrapper .active td {
    color: #fff !important; }

.operations-view-wrapper .full-btn {
  font-size: 15px;
  line-height: 35px;
  width: 100%;
  height: 35px;
  max-width: 278px;
  color: #fff;
  margin: auto;
  background: #4c872f;
  border: none;
  border-radius: 0;
  margin-bottom: 50px; }
  .operations-view-wrapper .full-btn:hover {
    background: rgba(76, 135, 47, 0.6); }

.operations-view-wrapper .top-block-wrap {
  padding: 40px 20px 20px; }
  .operations-view-wrapper .top-block-wrap .sidebar-title {
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 39px; }
  .operations-view-wrapper .top-block-wrap .user-mgt-block .block-title {
    color: #fff;
    font-size: 16px;
    margin-bottom: 30px; }
  .operations-view-wrapper .top-block-wrap .user-mgt-block .ant-form-item {
    margin-bottom: 16px; }
    .operations-view-wrapper .top-block-wrap .user-mgt-block .ant-form-item .ant-input {
      font-size: 13px; }
  .operations-view-wrapper .top-block-wrap .user-mgt-block .validation {
    color: #ff0000;
    margin-left: 2px; }
  .operations-view-wrapper .top-block-wrap .label-text {
    color: #fff;
    padding-top: 8px;
    font-size: 13px; }
  .operations-view-wrapper .top-block-wrap .ant-input,
  .operations-view-wrapper .top-block-wrap .ant-select-selection {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0; }
  .operations-view-wrapper .top-block-wrap .licence-holder-title {
    font-size: 13px;
    font-weight: 600;
    margin-top: 25px;
    color: #fff;
    margin-bottom: 10px; }
  .operations-view-wrapper .top-block-wrap .user-select {
    margin-left: -20px;
    width: calc(100% + 40px); }
    .operations-view-wrapper .top-block-wrap .user-select .ant-select-selection__rendered {
      line-height: 40px;
      margin-left: 18px; }
      .operations-view-wrapper .top-block-wrap .user-select .ant-select-selection__rendered .ant-select-search {
        line-height: 32px; }
  .operations-view-wrapper .top-block-wrap .right-form-block {
    margin-left: -20px;
    width: calc(100% + 40px);
    background: #fff; }
    .operations-view-wrapper .top-block-wrap .right-form-block .ant-list-item-meta-content {
      margin-left: 12px;
      padding-right: 40px;
      position: relative; }
    .operations-view-wrapper .top-block-wrap .right-form-block .ant-list-item-meta-title {
      margin-bottom: 0; }
    .operations-view-wrapper .top-block-wrap .right-form-block .user-note-titile-block {
      font-size: 13px;
      color: #000000;
      line-height: 18px;
      margin-bottom: 0; }
    .operations-view-wrapper .top-block-wrap .right-form-block .user-mail {
      font-size: 10px;
      line-height: 13px;
      color: #8e8e8e;
      margin-bottom: 0; }
    .operations-view-wrapper .top-block-wrap .right-form-block .delete-button {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%); }
      .operations-view-wrapper .top-block-wrap .right-form-block .delete-button .ant-btn-danger {
        font-size: 10px;
        padding: 0;
        color: #cb1919;
        border: none;
        background: transparent;
        box-shadow: none;
        vertical-align: middle; }
    .operations-view-wrapper .top-block-wrap .right-form-block .ant-list-item {
      padding: 15px 30px;
      margin-bottom: 0 !important; }

.operations-view-wrapper .user-sidebar-wrap {
  position: fixed;
  left: 0;
  top: 65px;
  width: 330px;
  background-color: #001427;
  color: #fff;
  z-index: 9;
  height: calc(100vh - 65px);
  max-height: 100vh;
  overflow-y: auto;
  scrollbar-width: none; }

.operations-view-wrapper .user-management-sidebar .caret-down {
  color: #d8d8d8;
  position: absolute;
  right: 8px;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%); }

.operations-view-wrapper .user-management-sidebar .ant-select-arrow,
.operations-view-wrapper .user-management-sidebar .ant-form-item-children-icon {
  display: none; }

.operations-view-wrapper .submit-block {
  text-align: center; }
  .operations-view-wrapper .submit-block .full-btn {
    margin-bottom: 0; }

.operations-view-wrapper .operation-inner-banner {
  font-size: 18px;
  color: #fff;
  padding: 12px 25px;
  background-color: #29435c; }

.operations-view-wrapper .ant-tabs-bar {
  padding: 0 28px;
  border-bottom: none;
  background: #e3e3e3;
  margin: 0; }

.operations-view-wrapper .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  border: none;
  font-size: 14px;
  color: #8a8a8a;
  padding: 0 15px 0 0;
  background: transparent; }
  .operations-view-wrapper .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab.ant-tabs-tab-active {
    font-weight: 600;
    color: #58a34a; }

.operations-view-wrapper .registration-request-wrapper {
  background-color: #f2ecec;
  padding: 20px 25px; }
  @media (max-width: 991px) {
    .operations-view-wrapper .registration-request-wrapper {
      padding: 15px; } }

.operations-view-wrapper .operation-search {
  margin-bottom: 20px; }
  .operations-view-wrapper .operation-search .user-search-field .ant-input-suffix {
    display: none; }
  .operations-view-wrapper .operation-search .user-search-field .ant-input {
    background-color: #fff;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    padding: 20px 20px;
    border: solid 1px #dddddd; }

.operations-view-wrapper .operation-table-wrapper {
  padding: 2px 0 20px 0px;
  background-color: #fff;
  border: solid 1px #dddddd; }
  .operations-view-wrapper .operation-table-wrapper .ant-table-thead > tr > th {
    background-color: #fff;
    font-weight: 600;
    border-bottom: none;
    font-size: 13px;
    padding: 16px 0 16px 5px !important; }
    .operations-view-wrapper .operation-table-wrapper .ant-table-thead > tr > th .ant-table-column-sorter {
      width: 20px;
      height: 24px;
      background-color: #fafafa;
      font-size: 8px;
      line-height: 24px;
      padding-top: 3px; }
      .operations-view-wrapper .operation-table-wrapper .ant-table-thead > tr > th .ant-table-column-sorter i {
        font-size: 10px; }
        .operations-view-wrapper .operation-table-wrapper .ant-table-thead > tr > th .ant-table-column-sorter i.on {
          color: #555; }
  .operations-view-wrapper .operation-table-wrapper .operation-table-wrapper .ant-table-thead {
    border-bottom: 1px solid #cbcbcb; }
  .operations-view-wrapper .operation-table-wrapper .ant-table-tbody > tr > td {
    font-weight: normal;
    color: #000;
    font-size: 13px;
    background: transparent !important;
    border-bottom: 1px solid #cbcbcb; }
  .operations-view-wrapper .operation-table-wrapper .ant-table-tbody > tr:hover > td {
    background: #efefef !important; }
  .operations-view-wrapper .operation-table-wrapper .ant-table-small {
    border: 0; }
  .operations-view-wrapper .operation-table-wrapper .ant-select-selection__rendered {
    font-size: 13px; }
  .operations-view-wrapper .operation-table-wrapper .ant-table-pagination {
    float: inherit;
    margin: 30px 0 15px; }
    .operations-view-wrapper .operation-table-wrapper .ant-table-pagination .ant-pagination-next .ant-pagination-item-link,
    .operations-view-wrapper .operation-table-wrapper .ant-table-pagination .ant-pagination-prev .ant-pagination-item-link {
      border: none; }
    .operations-view-wrapper .operation-table-wrapper .ant-table-pagination .ant-pagination-next {
      margin-left: -8px; }
    .operations-view-wrapper .operation-table-wrapper .ant-table-pagination .ant-pagination-item {
      margin-right: 8px; }
    .operations-view-wrapper .operation-table-wrapper .ant-table-pagination .ant-pagination-item-active {
      background: #58a34a; }
      .operations-view-wrapper .operation-table-wrapper .ant-table-pagination .ant-pagination-item-active a {
        color: #fff; }
  .operations-view-wrapper .operation-table-wrapper .user-managment-table .ant-table-tbody {
    border-right: none;
    border: 1px solid #e8e8e8 !important; }
  .operations-view-wrapper .operation-table-wrapper .user-managment-table th.ant-table-column-has-sorters.ant-table-column-sort {
    background: transparent; }

.operations-view-wrapper .billing-sidebar .ant-list-item {
  padding: 0 20px;
  border-bottom: none;
  cursor: pointer;
  margin-bottom: 0 !important; }
  .operations-view-wrapper .billing-sidebar .ant-list-item .user-note-titile-block,
  .operations-view-wrapper .billing-sidebar .ant-list-item .ant-list-item-meta-title {
    color: #fff;
    margin-bottom: 0;
    line-height: 45px; }
  .operations-view-wrapper .billing-sidebar .ant-list-item:hover {
    background: #29435c; }

.operations-view-wrapper .billing-sidebar .billing-sidebar-list,
.operations-view-wrapper .billing-sidebar .search-block {
  margin-left: -20px;
  width: calc(100% + 40px); }

.operations-view-wrapper .billing-sidebar .subscription-holder {
  margin-bottom: 30px; }
  .operations-view-wrapper .billing-sidebar .subscription-holder h3 {
    margin: 0;
    font-size: 16px;
    color: #fff; }

.operations-view-wrapper .billing-sidebar .search-block .ant-input {
  height: 50px;
  padding: 0 20px;
  border: none; }
  .operations-view-wrapper .billing-sidebar .search-block .ant-input:hover {
    border-color: #8a8a8a; }

.operations-view-wrapper .billing-sidebar .search-block .ant-input-suffix {
  display: none; }

.operations-view-wrapper .billing-inner-banner {
  padding: 12px 25px;
  background-color: #29435c; }
  .operations-view-wrapper .billing-inner-banner h2 {
    color: #ffffff;
    margin-bottom: 0;
    font-size: 18px; }

.operations-view-wrapper .billing-filter-block {
  padding: 15px;
  margin-bottom: 20px; }
  @media (max-width: 991px) {
    .operations-view-wrapper .billing-filter-block {
      padding-bottom: 5px; } }
  .operations-view-wrapper .billing-filter-block h2 {
    font-size: 14px;
    color: #000;
    font-family: "Open Sans", sans-serif; }
  .operations-view-wrapper .billing-filter-block .ant-select {
    margin-right: 15px;
    font-size: 13px; }
    @media (max-width: 991px) {
      .operations-view-wrapper .billing-filter-block .ant-select {
        margin-bottom: 15px; } }

.operations-view-wrapper .billing-table .ant-table-thead > tr > th > div {
  font-size: 14px;
  font-weight: 600;
  color: #000; }

.operations-view-wrapper .billing-table .ant-table-thead > tr > th {
  padding: 15px !important;
  background-color: transparent; }

.operations-view-wrapper .billing-table .ant-table-tbody > tr > td {
  font-size: 13px;
  padding: 15px !important;
  color: #000; }

.operations-view-wrapper .billing-table .ant-table-thead > tr > th .ant-table-column-sorter {
  background: #f2ecec;
  width: 20px;
  height: 20px;
  position: inherit;
  margin: 0 0 0 15px;
  display: inline-block;
  vertical-align: middle; }

.operations-view-wrapper .billing-table .ant-table-tbody > tr:hover > td {
  background: rgba(163, 163, 163, 0.17); }

.operations-view-wrapper .billing-table .ant-table-pagination {
  float: inherit;
  margin: 30px 0; }
  .operations-view-wrapper .billing-table .ant-table-pagination .ant-pagination-next .ant-pagination-item-link,
  .operations-view-wrapper .billing-table .ant-table-pagination .ant-pagination-prev .ant-pagination-item-link {
    border: none; }
  .operations-view-wrapper .billing-table .ant-table-pagination .ant-pagination-item-active {
    background: #58a34a; }
    .operations-view-wrapper .billing-table .ant-table-pagination .ant-pagination-item-active a {
      color: #fff; }
  .operations-view-wrapper .billing-table .ant-table-pagination .ant-pagination-item {
    margin-right: 8px; }

.operations-view-wrapper .registration-request-wrapper.billing-content-area {
  padding-bottom: 0; }
  .operations-view-wrapper .registration-request-wrapper.billing-content-area .search-filter {
    width: 100%;
    max-width: 250px;
    margin-top: 10px;
    display: block; }
    @media (min-width: 640px) {
      .operations-view-wrapper .registration-request-wrapper.billing-content-area .search-filter {
        margin-left: 10px;
        width: 150px;
        margin-top: 0;
        display: inline-block; } }
  .operations-view-wrapper .registration-request-wrapper.billing-content-area .select-block {
    display: block; }
    @media (min-width: 640px) {
      .operations-view-wrapper .registration-request-wrapper.billing-content-area .select-block {
        display: inline-block; } }
    @media (min-width: 992px) and (max-width: 1166px) {
      .operations-view-wrapper .registration-request-wrapper.billing-content-area .select-block {
        margin-top: 10px; } }
  .operations-view-wrapper .registration-request-wrapper.billing-content-area .table-wrap {
    min-height: 100%;
    background: #fff;
    border: 1px solid #d5d5d5; }
    .operations-view-wrapper .registration-request-wrapper.billing-content-area .table-wrap .responsive-table {
      border: 0 !important; }
    .operations-view-wrapper .registration-request-wrapper.billing-content-area .table-wrap .ant-table-small {
      border: 0;
      border-bottom: 1px solid #e8e8e8;
      border-radius: 0; }
    .operations-view-wrapper .registration-request-wrapper.billing-content-area .table-wrap .ant-table-body {
      margin: 0; }
  @media (min-width: 992px) {
    .operations-view-wrapper .registration-request-wrapper.billing-content-area {
      padding-right: 0;
      padding-bottom: 0; } }

.operations-view-wrapper .billing-right-section {
  margin-top: 15px; }
  @media (min-width: 992px) {
    .operations-view-wrapper .billing-right-section {
      margin-top: -20px;
      margin-right: 0; } }
  .operations-view-wrapper .billing-right-section .ant-empty-description {
    color: #fff; }
  .operations-view-wrapper .billing-right-section .inner-banner-content {
    padding: 19px 25px;
    background: #29435c;
    border-top: 2px solid #000; }
    .operations-view-wrapper .billing-right-section .inner-banner-content h2 {
      color: #ffffff;
      margin-bottom: 0;
      font-size: 18px;
      font-weight: 600; }
  .operations-view-wrapper .billing-right-section .date-info {
    cursor: pointer;
    display: block;
    color: #000;
    text-align: left;
    padding: 15px;
    background: #fff;
    border-left: 1px solid rgba(41, 67, 92, 0.3);
    border-right: 1px solid rgba(41, 67, 92, 0.3); }
    .operations-view-wrapper .billing-right-section .date-info .ant-calendar-picker-input {
      border: 0;
      cursor: pointer; }
    .operations-view-wrapper .billing-right-section .date-info .ant-calendar-range-picker-input {
      text-align: left;
      margin-left: 10px;
      cursor: pointer;
      width: 36%; }
      .operations-view-wrapper .billing-right-section .date-info .ant-calendar-range-picker-input::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: black;
        opacity: 1; }
      .operations-view-wrapper .billing-right-section .date-info .ant-calendar-range-picker-input::-moz-placeholder {
        /* Firefox 19+ */
        color: black;
        opacity: 1; }
      .operations-view-wrapper .billing-right-section .date-info .ant-calendar-range-picker-input:-ms-input-placeholder {
        /* IE 10+ */
        color: black;
        /*color: $color;
        opacity: $opacity;*/ }
      .operations-view-wrapper .billing-right-section .date-info .ant-calendar-range-picker-input::-ms-input-placeholder {
        /* Firefox 18- */
        color: black;
        opacity: 1; }
      .operations-view-wrapper .billing-right-section .date-info .ant-calendar-range-picker-input:-moz-placeholder {
        /* Firefox 18- */
        color: black;
        opacity: 1; }
      @media (min-width: 640px) {
        .operations-view-wrapper .billing-right-section .date-info .ant-calendar-range-picker-input {
          width: 40%; } }
    .operations-view-wrapper .billing-right-section .date-info .ant-calendar-range-picker-separator {
      color: #000000; }
    .operations-view-wrapper .billing-right-section .date-info .anticon-calendar {
      left: 0;
      color: #000; }
    .operations-view-wrapper .billing-right-section .date-info .ant-select-arrow {
      display: none; }
    .operations-view-wrapper .billing-right-section .date-info .ant-select-selection-selected-value {
      padding-right: inherit;
      font-size: 12px; }
      @media (min-width: 1200px) {
        .operations-view-wrapper .billing-right-section .date-info .ant-select-selection-selected-value {
          font-size: 14px; } }
  .operations-view-wrapper .billing-right-section .pdf-button-block {
    padding: 30px 20px;
    text-align: center;
    background-color: #29435c;
    border-bottom: 2px solid #000; }
    .operations-view-wrapper .billing-right-section .pdf-button-block button {
      height: 40px;
      background: #4c872f;
      border-radius: 0;
      width: 100%;
      max-width: 250px;
      margin: auto;
      border: none;
      color: #fff;
      font-size: 15px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
  .operations-view-wrapper .billing-right-section .edit-credit-block,
  .operations-view-wrapper .billing-right-section .custom-block,
  .operations-view-wrapper .billing-right-section .subscription-holder-block,
  .operations-view-wrapper .billing-right-section .event-block {
    padding: 24px 20px;
    display: inline-block;
    width: 100%;
    background: #29435c;
    border-bottom: 2px solid #000; }
    .operations-view-wrapper .billing-right-section .edit-credit-block .block-title,
    .operations-view-wrapper .billing-right-section .custom-block .block-title,
    .operations-view-wrapper .billing-right-section .subscription-holder-block .block-title,
    .operations-view-wrapper .billing-right-section .event-block .block-title {
      color: #fff;
      font-weight: 700;
      display: inline-block;
      vertical-align: middle;
      line-height: 46px; }
      @media (min-width: 992px) and (max-width: 1299px) {
        .operations-view-wrapper .billing-right-section .edit-credit-block .block-title,
        .operations-view-wrapper .billing-right-section .custom-block .block-title,
        .operations-view-wrapper .billing-right-section .subscription-holder-block .block-title,
        .operations-view-wrapper .billing-right-section .event-block .block-title {
          line-height: inherit;
          margin-bottom: 10px;
          display: block; } }
    .operations-view-wrapper .billing-right-section .edit-credit-block .value,
    .operations-view-wrapper .billing-right-section .custom-block .value,
    .operations-view-wrapper .billing-right-section .subscription-holder-block .value,
    .operations-view-wrapper .billing-right-section .event-block .value {
      background: #ffffff;
      padding: 12px 10px;
      height: 44px;
      float: right;
      width: 100%;
      max-width: 130px;
      font-size: 13px;
      color: #000; }
      @media (min-width: 992px) and (max-width: 1299px) {
        .operations-view-wrapper .billing-right-section .edit-credit-block .value,
        .operations-view-wrapper .billing-right-section .custom-block .value,
        .operations-view-wrapper .billing-right-section .subscription-holder-block .value,
        .operations-view-wrapper .billing-right-section .event-block .value {
          float: inherit;
          display: block; } }
    .operations-view-wrapper .billing-right-section .edit-credit-block .faded,
    .operations-view-wrapper .billing-right-section .custom-block .faded,
    .operations-view-wrapper .billing-right-section .subscription-holder-block .faded,
    .operations-view-wrapper .billing-right-section .event-block .faded {
      color: #c9c9c9; }
    .operations-view-wrapper .billing-right-section .edit-credit-block .holder-name,
    .operations-view-wrapper .billing-right-section .custom-block .holder-name,
    .operations-view-wrapper .billing-right-section .subscription-holder-block .holder-name,
    .operations-view-wrapper .billing-right-section .event-block .holder-name {
      display: block;
      color: #fff;
      padding-left: 20px;
      font-size: 13px; }
    .operations-view-wrapper .billing-right-section .edit-credit-block .un-replied-block,
    .operations-view-wrapper .billing-right-section .custom-block .un-replied-block,
    .operations-view-wrapper .billing-right-section .subscription-holder-block .un-replied-block,
    .operations-view-wrapper .billing-right-section .event-block .un-replied-block {
      margin-bottom: 20px; }
    .operations-view-wrapper .billing-right-section .edit-credit-block .ant-list-item,
    .operations-view-wrapper .billing-right-section .custom-block .ant-list-item,
    .operations-view-wrapper .billing-right-section .subscription-holder-block .ant-list-item,
    .operations-view-wrapper .billing-right-section .event-block .ant-list-item {
      border-bottom: none;
      margin-bottom: 0 !important;
      color: #fff;
      padding: 5px 20px;
      font-size: 13px; }
  .operations-view-wrapper .billing-right-section .event-block {
    border-bottom: none; }
  .operations-view-wrapper .billing-right-section .detail-wrap {
    height: 100%;
    min-height: 100%;
    background: #29435c; }

.operations-view-wrapper .chat-command-sidebar .chat-info-block .title {
  margin-bottom: 20px;
  font-size: 16px;
  color: #fff; }

.operations-view-wrapper .chat-command-sidebar .chat-info-block .info {
  margin: 0;
  font-size: 14px;
  color: #fff;
  line-height: 24px; }

.operations-view-wrapper .chat-command-sidebar .chat-list {
  padding: 20px; }

.operations-view-wrapper .chat-command-sidebar .list-item-block .ant-avatar,
.operations-view-wrapper .chat-command-sidebar .list-item-block .ant-list-item-meta-avatar {
  width: 50px;
  height: 50px; }

.operations-view-wrapper .chat-command-sidebar .list-item-block .ant-list-item {
  padding: 0;
  margin-bottom: 0 !important; }
  .operations-view-wrapper .chat-command-sidebar .list-item-block .ant-list-item:hover .ant-list-item-meta-title a {
    color: rgba(255, 255, 255, 0.6); }

.operations-view-wrapper .chat-command-sidebar .list-item-block .ant-list-item-meta-content {
  padding-left: 20px; }

.operations-view-wrapper .chat-command-sidebar .list-item-block .ant-list-item-meta-title {
  margin-bottom: 0; }
  .operations-view-wrapper .chat-command-sidebar .list-item-block .ant-list-item-meta-title a {
    margin-bottom: 0;
    line-height: 50px;
    color: #fff;
    display: block; }

.operations-view-wrapper .chat-inner-banner {
  padding: 12px 25px;
  background-color: #29435c; }
  .operations-view-wrapper .chat-inner-banner h2 {
    color: #ffffff;
    margin-bottom: 0;
    font-size: 18px; }

.operations-view-wrapper .chat-command-wrapper {
  background: #f2ecec;
  padding: 20px; }
  .operations-view-wrapper .chat-command-wrapper .table-wrap {
    min-height: 100%; }
  .operations-view-wrapper .chat-command-wrapper .chat-search-block {
    margin-bottom: 20px; }
    .operations-view-wrapper .chat-command-wrapper .chat-search-block .search-field .ant-input-suffix {
      display: none; }
    .operations-view-wrapper .chat-command-wrapper .chat-search-block .search-field .ant-input {
      background-color: #fff;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      padding: 20px 20px;
      border: solid 1px #dddddd; }
  .operations-view-wrapper .chat-command-wrapper .chat-command-table {
    background: #fff;
    border: solid 1px #e8e8e8; }
    .operations-view-wrapper .chat-command-wrapper .chat-command-table .ant-table-small {
      border-radius: 0;
      border: none;
      border-bottom: 1px solid #e8e8e8; }
    .operations-view-wrapper .chat-command-wrapper .chat-command-table .ant-table-body {
      margin: 0 !important; }
    .operations-view-wrapper .chat-command-wrapper .chat-command-table .ant-table-tbody > tr > td,
    .operations-view-wrapper .chat-command-wrapper .chat-command-table .ant-table-thead > tr > th {
      padding: 16px !important; }
    .operations-view-wrapper .chat-command-wrapper .chat-command-table .ant-table-thead > tr > th {
      border-radius: 0;
      font-weight: 600; }
    .operations-view-wrapper .chat-command-wrapper .chat-command-table .ant-table-tbody > tr:hover td,
    .operations-view-wrapper .chat-command-wrapper .chat-command-table .ant-table-thead > tr > th:hover {
      background: #e3e3e3; }
    .operations-view-wrapper .chat-command-wrapper .chat-command-table .ant-table-tbody > tr > td {
      font-size: 13px; }
      .operations-view-wrapper .chat-command-wrapper .chat-command-table .ant-table-tbody > tr > td .ant-btn-danger {
        border: none;
        background: transparent;
        color: #bb1a1c;
        box-shadow: none; }
        .operations-view-wrapper .chat-command-wrapper .chat-command-table .ant-table-tbody > tr > td .ant-btn-danger .anticon-delete {
          font-size: 18px; }
        .operations-view-wrapper .chat-command-wrapper .chat-command-table .ant-table-tbody > tr > td .ant-btn-danger span {
          display: none; }
  .operations-view-wrapper .chat-command-wrapper .ant-table-pagination.ant-pagination {
    float: inherit;
    margin: 30px 0;
    padding: 0 16px; }
    .operations-view-wrapper .chat-command-wrapper .ant-table-pagination.ant-pagination .ant-pagination-next .ant-pagination-item-link,
    .operations-view-wrapper .chat-command-wrapper .ant-table-pagination.ant-pagination .ant-pagination-prev .ant-pagination-item-link {
      border: none; }
    .operations-view-wrapper .chat-command-wrapper .ant-table-pagination.ant-pagination .ant-pagination-item-active {
      background: #58a34a; }
      .operations-view-wrapper .chat-command-wrapper .ant-table-pagination.ant-pagination .ant-pagination-item-active a {
        color: #fff; }
  .operations-view-wrapper .chat-command-wrapper .ant-table-thead > tr > th .ant-table-column-sorter {
    background: #f2ecec;
    width: 20px;
    height: 20px;
    position: inherit;
    margin: 0 0 0 15px;
    display: inline-block;
    vertical-align: middle; }

.operations-view-wrapper .registration-sidebar .registration-info-block .title {
  margin-bottom: 20px;
  font-size: 16px;
  color: #fff; }

.operations-view-wrapper .registration-sidebar .registration-info-block .info {
  margin-top: 20px;
  font-size: 14px;
  color: #fff;
  line-height: 24px; }

.operations-view-wrapper .registration-inner-banner {
  padding: 12px 25px;
  background-color: #29435c; }
  .operations-view-wrapper .registration-inner-banner h2 {
    color: #ffffff;
    margin-bottom: 0;
    font-size: 18px; }

.operations-view-wrapper .registration-request-wrapper {
  background: #f2ecec;
  padding: 20px; }
  .operations-view-wrapper .registration-request-wrapper .registration-search-block {
    margin-bottom: 20px; }
    .operations-view-wrapper .registration-request-wrapper .registration-search-block .search-field .ant-input-suffix {
      display: none; }
    .operations-view-wrapper .registration-request-wrapper .registration-search-block .search-field .ant-input {
      background-color: #fff;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      padding: 20px 20px;
      border: solid 1px #dddddd; }
  .operations-view-wrapper .registration-request-wrapper .residtration-table-wrap {
    background: #fff; }
    .operations-view-wrapper .registration-request-wrapper .residtration-table-wrap .ant-select-selection__rendered {
      color: #000; }
    .operations-view-wrapper .registration-request-wrapper .residtration-table-wrap .ant-table-small {
      border-radius: 0;
      border: none;
      border-bottom: 1px solid #e8e8e8; }
    .operations-view-wrapper .registration-request-wrapper .residtration-table-wrap .ant-table-body {
      margin: 0 !important; }
    .operations-view-wrapper .registration-request-wrapper .residtration-table-wrap .ant-table-tbody > tr > td,
    .operations-view-wrapper .registration-request-wrapper .residtration-table-wrap .ant-table-thead > tr > th {
      padding: 16px !important; }
    .operations-view-wrapper .registration-request-wrapper .residtration-table-wrap .ant-table-thead > tr > th {
      font-weight: 600;
      border-radius: 0;
      border-radius: 0;
      padding-right: 30px !important; }
    .operations-view-wrapper .registration-request-wrapper .residtration-table-wrap .ant-table-tbody > tr:hover td,
    .operations-view-wrapper .registration-request-wrapper .residtration-table-wrap .ant-table-thead > tr > th:hover {
      background: #e3e3e3; }
    .operations-view-wrapper .registration-request-wrapper .residtration-table-wrap .ant-table-tbody > tr > td {
      font-size: 13px;
      color: #000; }
      .operations-view-wrapper .registration-request-wrapper .residtration-table-wrap .ant-table-tbody > tr > td .ant-select-selection {
        font-size: 13px; }
      .operations-view-wrapper .registration-request-wrapper .residtration-table-wrap .ant-table-tbody > tr > td .ant-btn-danger {
        border: none;
        background: transparent;
        color: #bb1a1c;
        box-shadow: none; }
        .operations-view-wrapper .registration-request-wrapper .residtration-table-wrap .ant-table-tbody > tr > td .ant-btn-danger .anticon-delete {
          font-size: 18px; }
        .operations-view-wrapper .registration-request-wrapper .residtration-table-wrap .ant-table-tbody > tr > td .ant-btn-danger span {
          display: none; }
    .operations-view-wrapper .registration-request-wrapper .residtration-table-wrap .table-buton-block {
      background: #fff;
      padding: 5px 10px 5px 5px;
      display: inline-block; }
      .operations-view-wrapper .registration-request-wrapper .residtration-table-wrap .table-buton-block .danger-block {
        padding-left: 10px; }
    .operations-view-wrapper .registration-request-wrapper .residtration-table-wrap .table-close-icon {
      border-radius: 4px;
      border: 1px solid #d9d9d9 !important; }
      .operations-view-wrapper .registration-request-wrapper .residtration-table-wrap .table-close-icon:hover {
        border-color: #bb1a1c !important; }
    .operations-view-wrapper .registration-request-wrapper .residtration-table-wrap .ant-table-thead > tr > th .ant-table-column-sorter {
      background: #f2ecec;
      width: 20px;
      height: 20px; }
  .operations-view-wrapper .registration-request-wrapper .ant-table-pagination.ant-pagination {
    float: inherit;
    margin: 30px 0;
    padding: 0 16px; }
    .operations-view-wrapper .registration-request-wrapper .ant-table-pagination.ant-pagination .ant-pagination-next .ant-pagination-item-link,
    .operations-view-wrapper .registration-request-wrapper .ant-table-pagination.ant-pagination .ant-pagination-prev .ant-pagination-item-link {
      border: none; }
    .operations-view-wrapper .registration-request-wrapper .ant-table-pagination.ant-pagination .ant-pagination-next {
      margin-left: -8px; }
    .operations-view-wrapper .registration-request-wrapper .ant-table-pagination.ant-pagination .ant-pagination-item-active {
      background: #58a34a; }
      .operations-view-wrapper .registration-request-wrapper .ant-table-pagination.ant-pagination .ant-pagination-item-active a {
        color: #fff; }
    .operations-view-wrapper .registration-request-wrapper .ant-table-pagination.ant-pagination .ant-pagination-item a {
      font-weight: normal; }

.operations-view-wrapper .event-management-sidebar .block-title {
  margin-bottom: 30px; }
  .operations-view-wrapper .event-management-sidebar .block-title h2 {
    margin: 0;
    font-size: 16px;
    color: #fff; }

.operations-view-wrapper .event-management-sidebar .ant-empty {
  color: rgba(255, 255, 255, 0.3); }
  .operations-view-wrapper .event-management-sidebar .ant-empty .ant-empty-image {
    margin-bottom: 10px; }

.operations-view-wrapper .event-management-sidebar .search-block {
  width: calc(100% + 40px);
  margin-left: -20px; }
  .operations-view-wrapper .event-management-sidebar .search-block .ant-input {
    height: 50px;
    border: 0;
    padding-left: 20px; }

.operations-view-wrapper .event-management-sidebar .event-users-list {
  width: calc(100% + 40px);
  margin-left: -20px; }

.operations-view-wrapper .event-management-sidebar .ant-list-split .ant-list-item {
  border: none;
  margin-bottom: 0 !important;
  padding: 15px 20px;
  cursor: pointer; }
  .operations-view-wrapper .event-management-sidebar .ant-list-split .ant-list-item:hover {
    background: #29435c; }

.operations-view-wrapper .event-management-sidebar .active {
  background: #29435c; }

.operations-view-wrapper .event-management-sidebar .ant-list-item-meta-title {
  margin-bottom: 0; }

.operations-view-wrapper .event-management-sidebar .user-note-titile-block {
  color: #fff;
  font-size: 14px;
  margin-bottom: 0; }

.operations-view-wrapper .event-management-wrapper .complete-event-block {
  background: #29435c;
  padding-top: 20px;
  min-height: 100vh; }
  .operations-view-wrapper .event-management-wrapper .complete-event-block .title-block-wrap {
    background: #29435c;
    height: auto;
    padding: 0 10px 0 28px;
    border-bottom: solid 8px #e3e3e3; }
  .operations-view-wrapper .event-management-wrapper .complete-event-block .main-title {
    display: inline-block; }
    @media (max-width: 991px) {
      .operations-view-wrapper .event-management-wrapper .complete-event-block .main-title {
        padding-top: 8px; } }
    .operations-view-wrapper .event-management-wrapper .complete-event-block .main-title h2 {
      margin: 0 0 15px;
      font-size: 16px;
      color: #fff; }
  .operations-view-wrapper .event-management-wrapper .complete-event-block .btn-block-wrap {
    display: block;
    margin-bottom: 15px; }
    .operations-view-wrapper .event-management-wrapper .complete-event-block .btn-block-wrap .event-complete-btn {
      background: #001427;
      color: #fff;
      font-size: 14px;
      border-radius: 0;
      height: 40px;
      border: none;
      -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
      .operations-view-wrapper .event-management-wrapper .complete-event-block .btn-block-wrap .event-complete-btn:hover {
        background: rgba(0, 0, 0, 0.8); }
    .operations-view-wrapper .event-management-wrapper .complete-event-block .btn-block-wrap .button-label {
      padding-bottom: 2px; }
  .operations-view-wrapper .event-management-wrapper .complete-event-block .content-block {
    border-left: solid 8px #f2ecec; }
  .operations-view-wrapper .event-management-wrapper .complete-event-block .assigned-staff-block {
    padding: 20px;
    background: #fff;
    border-bottom: solid 4px #f2ecec; }
    .operations-view-wrapper .event-management-wrapper .complete-event-block .assigned-staff-block .select-drop {
      margin: 10px 0 20px; }
    .operations-view-wrapper .event-management-wrapper .complete-event-block .assigned-staff-block h3 {
      font-size: 16px;
      color: #000;
      font-weight: 600;
      margin-bottom: 5px; }
    .operations-view-wrapper .event-management-wrapper .complete-event-block .assigned-staff-block .ant-list-item-meta-content {
      margin-left: 12px;
      padding-right: 40px; }
    .operations-view-wrapper .event-management-wrapper .complete-event-block .assigned-staff-block .ant-list-item-meta-title {
      margin-bottom: 0; }
    .operations-view-wrapper .event-management-wrapper .complete-event-block .assigned-staff-block .user-note-titile-block {
      font-size: 13px;
      color: #000000;
      line-height: 18px;
      margin-bottom: 0; }
    .operations-view-wrapper .event-management-wrapper .complete-event-block .assigned-staff-block .ant-list-item-meta-description {
      font-size: 10px;
      line-height: 13px;
      color: #8e8e8e; }
    .operations-view-wrapper .event-management-wrapper .complete-event-block .assigned-staff-block .ant-list-item-meta-avatar {
      position: relative; }
      .operations-view-wrapper .event-management-wrapper .complete-event-block .assigned-staff-block .ant-list-item-meta-avatar .ant-avatar {
        width: 40px;
        height: 40px; }
    .operations-view-wrapper .event-management-wrapper .complete-event-block .assigned-staff-block .delete-button {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      z-index: 9;
      width: 40px;
      height: 40px;
      line-height: 40px;
      background: rgba(203, 25, 25, 0.9);
      -webkit-transition: all 0.2s linear;
      -moz-transition: all 0.2s linear;
      transition: all 0.2s linear; }
      .operations-view-wrapper .event-management-wrapper .complete-event-block .assigned-staff-block .delete-button .ant-btn-danger {
        padding: 0;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: transparent;
        border: none;
        box-shadow: none;
        color: #fff; }
        .operations-view-wrapper .event-management-wrapper .complete-event-block .assigned-staff-block .delete-button .ant-btn-danger span {
          display: none; }
      .operations-view-wrapper .event-management-wrapper .complete-event-block .assigned-staff-block .delete-button .anticon-delete {
        z-index: 3;
        display: inline-block; }
    .operations-view-wrapper .event-management-wrapper .complete-event-block .assigned-staff-block .ant-list-item {
      padding-right: 20px;
      display: inline-block;
      border: none;
      position: relative;
      margin-bottom: 0 !important; }
      .operations-view-wrapper .event-management-wrapper .complete-event-block .assigned-staff-block .ant-list-item:hover .delete-button {
        opacity: 1;
        visibility: visible; }
  .operations-view-wrapper .event-management-wrapper .complete-event-block .un-replied-block {
    padding: 20px;
    background: #fff;
    border-bottom: solid 4px #f2ecec; }
    .operations-view-wrapper .event-management-wrapper .complete-event-block .un-replied-block h3 {
      font-size: 16px;
      color: #000;
      font-weight: 600;
      margin-bottom: 10px; }
  .operations-view-wrapper .event-management-wrapper .complete-event-block .un-downloaded-assets-block {
    padding: 20px;
    background: #fff;
    border-bottom: solid 4px #f2ecec; }
    .operations-view-wrapper .event-management-wrapper .complete-event-block .un-downloaded-assets-block h3 {
      font-size: 16px;
      color: #000;
      font-weight: 600;
      margin-bottom: 10px; }
    .operations-view-wrapper .event-management-wrapper .complete-event-block .un-downloaded-assets-block .ant-list-split .ant-list-item {
      border-bottom: none;
      padding: 3px 0; }
      .operations-view-wrapper .event-management-wrapper .complete-event-block .un-downloaded-assets-block .ant-list-split .ant-list-item:last-child {
        padding-bottom: 0;
        margin-bottom: 0; }
      .operations-view-wrapper .event-management-wrapper .complete-event-block .un-downloaded-assets-block .ant-list-split .ant-list-item a {
        font-size: 14px; }
  .operations-view-wrapper .event-management-wrapper .complete-event-block .editors-note-block {
    padding: 20px;
    background: #fff; }
    .operations-view-wrapper .event-management-wrapper .complete-event-block .editors-note-block h3 {
      font-size: 16px;
      color: #000;
      font-weight: 600;
      margin-bottom: 10px; }
    .operations-view-wrapper .event-management-wrapper .complete-event-block .editors-note-block .ant-list-item-meta-title {
      color: #000;
      font-size: 12px;
      margin-bottom: 15px; }
    .operations-view-wrapper .event-management-wrapper .complete-event-block .editors-note-block .date-block {
      color: #000;
      font-size: 14px; }
    .operations-view-wrapper .event-management-wrapper .complete-event-block .editors-note-block .ant-list-split .ant-list-item {
      border-bottom: none;
      margin-bottom: 20px;
      padding: 3px 0; }
      .operations-view-wrapper .event-management-wrapper .complete-event-block .editors-note-block .ant-list-split .ant-list-item:last-child {
        padding-bottom: 0;
        margin-bottom: 0; }
  @media (min-width: 992px) {
    .operations-view-wrapper .event-management-wrapper .complete-event-block {
      padding-top: 0; }
      .operations-view-wrapper .event-management-wrapper .complete-event-block .title-block-wrap {
        height: auto; }
      .operations-view-wrapper .event-management-wrapper .complete-event-block .main-title h2 {
        margin: 0;
        font-size: 16px;
        line-height: 50px; }
      .operations-view-wrapper .event-management-wrapper .complete-event-block .btn-block-wrap {
        line-height: 50px;
        float: right;
        margin-bottom: 0; } }

.operations-view-wrapper .event-management-wrapper .editors-block {
  min-height: 100%;
  background-color: #fff;
  border-left: solid 8px #f2ecec;
  border-right: solid 8px #f2ecec; }
  .operations-view-wrapper .event-management-wrapper .editors-block .main-title {
    background: #29435c;
    margin: 0 -8px;
    height: 58px;
    padding: 0 10px 0 28px;
    border-bottom: solid 8px #e3e3e3; }
    .operations-view-wrapper .event-management-wrapper .editors-block .main-title h2 {
      margin: 0;
      font-size: 16px;
      color: #fff;
      line-height: 50px; }
  .operations-view-wrapper .event-management-wrapper .editors-block .search-editors-wrap {
    min-height: 100%; }
  .operations-view-wrapper .event-management-wrapper .editors-block .search-block .ant-input {
    padding: 12px 50px 12px 25px;
    height: auto;
    border: none !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }
    .operations-view-wrapper .event-management-wrapper .editors-block .search-block .ant-input:hover, .operations-view-wrapper .event-management-wrapper .editors-block .search-block .ant-input:focus {
      border: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none; }
  .operations-view-wrapper .event-management-wrapper .editors-block .search-block .ant-input-suffix {
    right: 25px;
    -webkit-opacity: 0.4;
    -moz-opacity: 0.4;
    opacity: 0.4;
    font-size: 18px; }
  .operations-view-wrapper .event-management-wrapper .editors-block .event-users-list .ant-list-item {
    padding: 10px 20px;
    background-color: #fff;
    cursor: pointer;
    margin: 0 !important;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    transition: all 0.2s linear;
    border: none;
    display: block; }
    .operations-view-wrapper .event-management-wrapper .editors-block .event-users-list .ant-list-item:hover {
      background-color: #eee; }
    .operations-view-wrapper .event-management-wrapper .editors-block .event-users-list .ant-list-item.active {
      background-color: #bc5151; }
      .operations-view-wrapper .event-management-wrapper .editors-block .event-users-list .ant-list-item.active .list-content-wrap .name-block {
        color: #fff; }
      .operations-view-wrapper .event-management-wrapper .editors-block .event-users-list .ant-list-item.active .list-content-wrap .mail-block {
        color: #fff; }
      .operations-view-wrapper .event-management-wrapper .editors-block .event-users-list .ant-list-item.active .list-content-wrap .button-option {
        color: #fff; }
      .operations-view-wrapper .event-management-wrapper .editors-block .event-users-list .ant-list-item.active .count-value {
        border-color: #fff; }
  .operations-view-wrapper .event-management-wrapper .editors-block .event-users-list .user-list-content {
    display: block; }
  .operations-view-wrapper .event-management-wrapper .editors-block .event-users-list .ant-list-item-meta-avatar {
    float: left; }
    .operations-view-wrapper .event-management-wrapper .editors-block .event-users-list .ant-list-item-meta-avatar .ant-avatar {
      width: 40px;
      height: 40px; }
  .operations-view-wrapper .event-management-wrapper .editors-block .list-content-wrap {
    min-height: 40px;
    width: 100%;
    padding-left: 15px;
    padding-right: 30px;
    position: relative; }
    .operations-view-wrapper .event-management-wrapper .editors-block .list-content-wrap .name-block {
      font-size: 14px;
      font-weight: 600;
      color: #000; }
    .operations-view-wrapper .event-management-wrapper .editors-block .list-content-wrap .mail-block {
      font-size: 12px;
      color: #000;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: normal; }
    .operations-view-wrapper .event-management-wrapper .editors-block .list-content-wrap .button-option {
      position: absolute;
      right: 0;
      top: 50%;
      display: table;
      color: #b2b2b2;
      font-size: 12px;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      transform: translateY(-50%); }
      .operations-view-wrapper .event-management-wrapper .editors-block .list-content-wrap .button-option i {
        font-size: 20px; }
    .operations-view-wrapper .event-management-wrapper .editors-block .list-content-wrap .count-value {
      width: 24px;
      height: 24px;
      border: solid 2px #b2b2b2;
      text-align: center;
      display: table-cell;
      vertical-align: middle;
      font-weight: 600;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%; }

.operations-view-wrapper .event-management-wrapper .assigned-events-wrapper {
  min-height: 100%;
  background-color: #fff;
  border-right: solid 8px #f2ecec; }
  .operations-view-wrapper .event-management-wrapper .assigned-events-wrapper .main-title {
    background: #29435c;
    height: 58px;
    padding: 0 10px 0 28px;
    margin: 0 -8px;
    border-bottom: solid 8px #e3e3e3; }
    .operations-view-wrapper .event-management-wrapper .assigned-events-wrapper .main-title h2 {
      margin: 0;
      font-size: 16px;
      color: #fff;
      line-height: 50px; }
  .operations-view-wrapper .event-management-wrapper .assigned-events-wrapper .event-users-list-wrapper {
    min-height: 100%;
    padding: 45px 0; }
  .operations-view-wrapper .event-management-wrapper .assigned-events-wrapper .ant-list-item {
    padding: 12px 20px;
    background-color: #fff;
    cursor: pointer;
    margin: 0 !important;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    transition: all 0.2s linear;
    border: none;
    display: block; }
    .operations-view-wrapper .event-management-wrapper .assigned-events-wrapper .ant-list-item:hover {
      background-color: #eee; }
    .operations-view-wrapper .event-management-wrapper .assigned-events-wrapper .ant-list-item.active {
      background-color: #eee; }
    .operations-view-wrapper .event-management-wrapper .assigned-events-wrapper .ant-list-item .event-name-wrap {
      font-size: 14px;
      color: #000;
      position: relative;
      padding-left: 30px; }
      .operations-view-wrapper .event-management-wrapper .assigned-events-wrapper .ant-list-item .event-name-wrap .icon-block {
        position: absolute;
        left: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        transform: translateY(-50%);
        font-size: 16px;
        color: #000; }

.operations-sidemenu-enabled {
  padding-left: 330px; }

@media (max-width: 767px) {
  .operations-view-wrapper .user-sidebar-wrap {
    position: static;
    width: 100%;
    height: auto;
    max-height: 400px; }
  .operations-sidemenu-enabled {
    padding-left: 0; } }

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  background-color: #f5f6f7; }

.btn {
  background-color: transparent;
  border-radius: 0;
  border: none;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  white-space: nowrap;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  line-height: normal;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  transition: all 0.2s linear; }

button.btn.back-btn {
  line-height: 1.499;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 0px;
  white-space: nowrap;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 4px;
  height: 32px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;
  box-shadow: #5a5a5a 0px 3px 7px -1px;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-color: #d9d9d9; }

.btn:focus {
  outline: none; }

a {
  text-decoration: none !important;
  color: #4e9941; }

h2 {
  font-weight: 700; }

#root {
  width: 100%;
  min-height: 100%;
  overflow: hidden; }

.overlay {
  position: fixed;
  /* Sit on top of the page content */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  /* Add a pointer on hover */
  display: flex;
  align-items: center;
  align-content: center; }

.container {
  max-width: 1140px;
  width: 90%;
  margin: auto; }

.custom-card {
  margin-bottom: 20px; }
  .custom-card.mb-0 {
    margin-bottom: 0; }
  .custom-card .custom-card-header {
    background-color: #f3f3f3;
    border: solid 1px #d5d5d5;
    padding: 15px 20px;
    margin: 0;
    -webkit-border-radius: 3px 3px 0 0;
    -moz-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0; }
    .custom-card .custom-card-header h2 {
      font-size: 14px;
      margin-bottom: 0; }
    .custom-card .custom-card-header .back-btn {
      margin-top: 10px; }
  .custom-card .custom-card-contents {
    background-color: #fff;
    padding: 30px 20px;
    border: solid 1px #d5d5d5;
    border-top: none; }
  @media (min-width: 768px) {
    .custom-card .custom-card-header h2 {
      display: inline-block; }
    .custom-card .custom-card-header .back-btn {
      margin-top: -5px;
      float: right; } }

.responsive-table {
  overflow-x: auto;
  overflow-y: hidden; }
  .responsive-table .ant-table-wrapper {
    min-width: 600px; }

.common-form .ant-input {
  height: 40px; }

.common-form textarea.ant-input {
  height: auto; }

.common-form .ant-form-explain {
  font-size: 12px; }

.common-form.ant-form-vertical .ant-form-item-label {
  padding-bottom: 5px; }

.common-form.ant-form-vertical .ant-form-item {
  padding-bottom: 3px; }

.common-form .ant-form-item-label {
  line-height: normal; }
  .common-form .ant-form-item-label label {
    font-weight: 500;
    margin-bottom: 0;
    color: rgba(0, 0, 0, 0.4);
    display: block; }

.common-form.max-width-600 {
  max-width: 600px; }

.common-form.max-width-400 {
  max-width: 400px; }

.common-form .ant-btn-primary {
  height: 40px;
  font-weight: 600;
  padding: 0 20px;
  letter-spacing: 1px; }

.common-form .mb-0 {
  margin-bottom: 0 !important; }

.common-form .ant-calendar-picker {
  width: 100%; }

.ant-modal .ant-modal-footer {
  padding: 15px 16px; }
  .ant-modal .ant-modal-footer .ant-btn {
    height: 40px;
    font-weight: 600;
    padding: 0 20px;
    font-size: 14px;
    letter-spacing: 1px; }

.ant-slider .ant-slider-rail {
  height: 6px; }

.ant-slider .ant-slider-track {
  background-color: #58a34a;
  height: 6px; }

.ant-slider .ant-slider-handle {
  width: 24px;
  height: 24px;
  margin-left: -12px;
  margin-top: -10px;
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border-width: 1px; }

.share-modal-box .share-modal-header {
  text-align: center;
  padding-top: 15px;
  border-bottom: 1px solid #eee;
  padding-bottom: 30px; }
  .share-modal-box .share-modal-header .done-icon {
    font-size: 40px;
    color: #58a34a;
    margin-bottom: 5px;
    display: inline-block; }
  .share-modal-box .share-modal-header .download-completed {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0; }

.share-modal-box .share-social-wrapper {
  padding-top: 30px;
  padding-bottom: 20px;
  text-align: center; }
  .share-modal-box .share-social-wrapper h3 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px;
    color: rgba(0, 0, 0, 0.6); }
  .share-modal-box .share-social-wrapper .social-item {
    display: inline-block;
    vertical-align: middle;
    margin: 0 5px;
    cursor: pointer;
    -webkit-opacity: 0.6;
    -moz-opacity: 0.6;
    opacity: 0.6;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    transition: all 0.2s linear; }
    .share-modal-box .share-social-wrapper .social-item:hover {
      -webkit-opacity: 0.9;
      -moz-opacity: 0.9;
      opacity: 0.9; }
    .share-modal-box .share-social-wrapper .social-item img {
      width: 36px; }

section.ant-layout {
  background-color: #ddd; }

div.ant-modal-mask,
div.ant-modal-wrap {
  z-index: 1050; }

.transaction-modal-wrapper {
  max-height: 400px; }
  .transaction-modal-wrapper .ant-modal-content {
    background: #f3eaeb; }
    .transaction-modal-wrapper .ant-modal-content .ant-modal-header {
      background-color: #ffffff !important; }
    .transaction-modal-wrapper .ant-modal-content .ant-modal-title {
      font-weight: 600;
      color: #000000 !important; }
    .transaction-modal-wrapper .ant-modal-content .ant-tabs-bar.ant-tabs-card-bar {
      height: 50px;
      border-bottom: none;
      margin-bottom: 25px; }
      .transaction-modal-wrapper .ant-modal-content .ant-tabs-bar.ant-tabs-card-bar .ant-tabs-nav-container {
        height: 50px; }
      .transaction-modal-wrapper .ant-modal-content .ant-tabs-bar.ant-tabs-card-bar .ant-tabs-nav {
        display: block; }
        .transaction-modal-wrapper .ant-modal-content .ant-tabs-bar.ant-tabs-card-bar .ant-tabs-nav .ant-tabs-tab {
          background-color: #646f7a;
          color: #ffffff;
          width: calc(50% - 5px);
          margin-right: 10px;
          text-align: center;
          border: none;
          border-radius: 4px;
          line-height: 48px;
          font-size: 15px; }
          .transaction-modal-wrapper .ant-modal-content .ant-tabs-bar.ant-tabs-card-bar .ant-tabs-nav .ant-tabs-tab:hover {
            background-color: #001428; }
          .transaction-modal-wrapper .ant-modal-content .ant-tabs-bar.ant-tabs-card-bar .ant-tabs-nav .ant-tabs-tab:last-child {
            margin-right: 0; }
          .transaction-modal-wrapper .ant-modal-content .ant-tabs-bar.ant-tabs-card-bar .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
            padding-bottom: 0;
            background-color: #001428; }
    .transaction-modal-wrapper .ant-modal-content .ant-tabs-content .ant-list {
      background-color: #ffffff;
      padding: 15px;
      border-radius: 4px;
      max-height: 500px;
      overflow-y: auto; }
    .transaction-modal-wrapper .ant-modal-content .transaction-pannel .ant-list-items {
      padding: 0;
      margin: 0; }
    .transaction-modal-wrapper .ant-modal-content .transaction-pannel .filter-block {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between; }
      .transaction-modal-wrapper .ant-modal-content .transaction-pannel .filter-block span {
        margin-bottom: 0;
        font-size: 18px;
        color: #2d4d66;
        font-weight: 100; }
      .transaction-modal-wrapper .ant-modal-content .transaction-pannel .filter-block .ant-select-enabled {
        width: calc(50% - 5px); }
        .transaction-modal-wrapper .ant-modal-content .transaction-pannel .filter-block .ant-select-enabled .anticon-down {
          font-size: 13px; }
    .transaction-modal-wrapper .ant-modal-content .transaction-pannel .ant-list-item {
      padding: 10px 0; }
      .transaction-modal-wrapper .ant-modal-content .transaction-pannel .ant-list-item .transaction-list-item-block {
        display: flex;
        justify-content: space-between; }
        .transaction-modal-wrapper .ant-modal-content .transaction-pannel .ant-list-item .transaction-list-item-block .ant-list-item-meta-title {
          color: #2d4d66;
          padding-left: 35px;
          position: relative; }
        .transaction-modal-wrapper .ant-modal-content .transaction-pannel .ant-list-item .transaction-list-item-block .ant-list-item-meta-title {
          font-size: 16px;
          margin-bottom: 0;
          font-weight: 100; }
        .transaction-modal-wrapper .ant-modal-content .transaction-pannel .ant-list-item .transaction-list-item-block .icon-block {
          font-size: 20px;
          margin-right: 15px;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%); }
        .transaction-modal-wrapper .ant-modal-content .transaction-pannel .ant-list-item .transaction-list-item-block .sub-text {
          font-size: 14px; }
      .transaction-modal-wrapper .ant-modal-content .transaction-pannel .ant-list-item .count {
        color: #000000;
        font-size: 18px;
        margin-bottom: 0; }
    .transaction-modal-wrapper .ant-modal-content .estimate-pannel .ant-list-items {
      padding: 0;
      margin: 0; }
    .transaction-modal-wrapper .ant-modal-content .estimate-pannel .ant-list-item {
      padding: 10px 0; }
      .transaction-modal-wrapper .ant-modal-content .estimate-pannel .ant-list-item .ant-list-item-meta {
        padding-right: 15px;
        max-width: calc(100% - 45%);
        display: inline-block; }
      .transaction-modal-wrapper .ant-modal-content .estimate-pannel .ant-list-item .right-block {
        width: 45%;
        text-align: right;
        color: #666;
        font-weight: 600; }
        .transaction-modal-wrapper .ant-modal-content .estimate-pannel .ant-list-item .right-block button {
          padding-right: 0;
          box-shadow: none;
          color: #666; }
        .transaction-modal-wrapper .ant-modal-content .estimate-pannel .ant-list-item .right-block p {
          margin: 0;
          color: #666; }
      .transaction-modal-wrapper .ant-modal-content .estimate-pannel .ant-list-item .ant-list-item-meta-title {
        margin-bottom: 0; }
        .transaction-modal-wrapper .ant-modal-content .estimate-pannel .ant-list-item .ant-list-item-meta-title .user-note-titile {
          font-size: 16px;
          margin-bottom: 0;
          font-weight: 600;
          color: #2d4d66;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
      @media (min-width: 375px) {
        .transaction-modal-wrapper .ant-modal-content .estimate-pannel .ant-list-item .ant-list-item-meta {
          max-width: calc(100% - 35%); }
        .transaction-modal-wrapper .ant-modal-content .estimate-pannel .ant-list-item .right-block {
          width: 35%; } }
  .transaction-modal-wrapper .create-event-btn {
    margin-top: 45px;
    width: 100%;
    height: 50px;
    display: block;
    text-align: center;
    color: #ffffff;
    border-radius: 4px !important;
    background-color: #021329; }
    .transaction-modal-wrapper .create-event-btn:hover {
      background-color: #646f7a;
      color: #ffffff; }
